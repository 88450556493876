import "core-js/modules/es.array.push.js";
export default {
  name: 'OrderPackageAddDialog',
  data() {
    return {
      module: 'orderPackage',
      formAction: '',
      form: {},
      readonly: false,
      status: 0,
      addition_pay_status: 0,
      trans_pay_status: 0
    };
  },
  methods: {
    async setForm({
      id,
      site_id,
      order_id,
      order_no,
      order_combine_id,
      order_combine_package_id,
      user_id,
      third_order_no,
      tracking_no,
      tracking_company,
      from_address,
      trans_amount,
      amount,
      addition_amount,
      trans_pay_status,
      addition_pay_status,
      is_photo,
      package_no,
      shelf_no,
      purchase_at,
      send_at,
      stored_at,
      out_at,
      status,
      status_text,
      user_status,
      pay_status,
      size_type,
      weight,
      remark,
      goods_quantity,
      order_package_together_id,
      tracking_from_time_at,
      orderGoods = [],
      allGoods = [],
      orderPackageTags = []
    }, showFields) {
      this.status = status;
      this.addition_pay_status = addition_pay_status || 0;
      this.trans_pay_status = trans_pay_status || 0;
      orderGoods = orderGoods || [];
      orderGoods.map(goods => {
        goods.form = {
          _empty: {
            label: '',
            value: '　',
            readonly: true,
            width: '100%'
          },
          order_goods_id: {
            show: false,
            value: goods.id
          },
          goods_info: {
            label: '商品',
            value: goods.title,
            readonly: true
          },
          addition_id: {
            value: goods.addition_id,
            label: '验货拍照',
            formType: 'select',
            options: '/addition/select?position_type_byte=1,2',
            required: false,
            methods: {
              change: async v => {
                const f = this.$refs[`additionForm${goods.id}`][0];
                await this.getAdditionAmount(f);
              }
            },
            readonly: status > 4 || addition_pay_status === 1
          },
          addition_comment: {
            show: m => m.addition_id > 0,
            value: goods.addition_comment,
            label: '拍照备注',
            formType: 'textarea',
            attrs: {
              autosize: {
                minRows: 2,
                maxRows: 4
              }
            },
            max: 500,
            required: false,
            readonly: status > 4 || addition_pay_status === 1
          },
          goods_quantity: {
            value: goods.goods_quantity,
            label: '拍照商品数量',
            formType: 'number',
            max: 1000,
            required: false,
            methods: {
              change: async v => {
                const f = this.$refs[`additionForm${goods.id}`][0];
                await this.getAdditionAmount(f);
              }
            },
            readonly: status > 4 || addition_pay_status === 1
          },
          addition_amount: {
            value: goods.addition_amount,
            label: '拍照费用',
            formType: 'amount',
            required: false,
            readonly: status > 4 || addition_pay_status === 1
          },
          addition_amount_comment: {
            show: m => m.addition_amount > 0,
            value: goods.addition_amount_comment,
            label: '拍照费用说明',
            formType: 'textarea',
            attrs: {
              autosize: {
                minRows: 2,
                maxRows: 4
              }
            },
            max: 500,
            required: false,
            readonly: status > 4 || addition_pay_status === 1
          },
          free_thumbs: {
            label: '入库拍照',
            value: goods.orderPackagePhotos ? goods.orderPackagePhotos.filter(i => i.is_addition === 0).map(i => i.thumb) : [],
            formType: 'upload',
            attrs: {
              accept: '.jpg,.png,.jpeg',
              limit: 20
            },
            type: 'array',
            required: false,
            width: '48%',
            readonly: status > 4 || addition_pay_status === 1
          },
          addition_thumbs: {
            label: '增值拍照',
            value: goods.orderPackagePhotos ? goods.orderPackagePhotos.filter(i => i.is_addition === 1).map(i => i.thumb) : [],
            formType: 'upload',
            attrs: {
              accept: '.jpg,.png,.jpeg',
              limit: 20
            },
            type: 'array',
            required: false,
            width: '48%',
            readonly: status > 4 || addition_pay_status === 1
          }
        };
        return goods;
      });
      let together_goods = [];
      if (site_id > 2 && allGoods.length === 1 && allGoods[0].third_seller_id) {
        const {
          data
        } = await this.$w_fun.post(`orderGoods/select`, {
          site_id_match: site_id,
          third_seller_id_match: allGoods[0].third_seller_id,
          status_match: 1,
          id_unmatch: id
        });
        together_goods = data.list;
      }
      const form = {
        order_id: {
          show: false,
          value: order_id
        },
        bar_code: {
          label: '包裹码',
          value: 'https://api.paijianggo.com/index/index/barcode?code=' + package_no,
          show: !!package_no,
          formType: 'upload',
          readonly: true
        },
        order_no: {
          show: m => m.order_id,
          label: '订单号',
          value: order_no,
          formType: 'orderNo'
        },
        order_goods_id: {
          show: m => m.order_id,
          value: orderGoods.map(i => i.id),
          label: '商品',
          formType: 'select',
          options: allGoods.map(i => {
            return {
              label: i.title,
              value: i.id
            };
          }),
          attrs: {
            multiple: true
          },
          readonly: status >= 4
        },
        status: {
          show: m => m.order_id,
          label: '状态',
          value: status,
          formType: 'radio',
          options: 'store/packageStatus',
          readonly: [2, 3, 4].indexOf(status) === -1,
          readonlyValue: status_text,
          attrs: {
            isButton: true
          }
        },
        third_order_no: {
          show: m => m.order_id,
          label: '平台订单号',
          value: third_order_no,
          required: false
        },
        amount: {
          show: m => m.order_id,
          label: '采购金额',
          value: amount,
          formType: 'amount',
          required: false,
          readonly: status >= 4
        },
        purchase_at: {
          show: m => m.order_id,
          label: '采购时间',
          value: purchase_at,
          formType: 'datePicker',
          required: false,
          attrs: {
            type: 'datetime'
          },
          readonly: status >= 4
        },
        tracking_from_time_at: {
          show: m => m.order_id,
          label: '发货时间',
          value: tracking_from_time_at,
          formType: 'datePicker',
          required: false,
          attrs: {
            type: 'datetime'
          },
          readonly: status >= 4
        },
        tracking_no: {
          show: m => m.order_id,
          label: '快递单号',
          value: tracking_no,
          required: false
        },
        tracking_company: {
          show: m => m.order_id,
          label: '快递公司',
          value: tracking_company,
          required: false
        },
        from_address: {
          show: m => m.order_id,
          label: '发货地址',
          value: from_address,
          required: false
        },
        trans_amount: {
          show: m => m.order_id,
          label: '运费',
          value: trans_amount,
          formType: 'amount',
          required: false,
          readonly: status > 4 || trans_pay_status === 1
        },
        together_goods_ids: {
          show: together_goods.length > 0,
          value: [],
          label: '同捆商品',
          formType: 'select',
          options: together_goods,
          attrs: {
            multiple: true
          },
          required: false,
          readonly: status > 4
        },
        addition_amount: {
          label: '验货拍照费',
          value: addition_amount,
          formType: 'amount',
          required: false
        },
        package_no: {
          label: '包裹号',
          value: package_no,
          required: false
        },
        shelf_no: {
          label: '货架号',
          value: shelf_no,
          required: false
        },
        size_type: {
          value: size_type,
          label: '包裹大小',
          formType: 'radio',
          options: '/orderPackage/sizeType',
          attrs: {
            isButton: true
          },
          required: false
        },
        weight: {
          label: '包裹重量',
          value: weight,
          formType: 'number',
          attrs: {
            append: 'kg'
          },
          required: false
        },
        order_package_tags: {
          label: '标签',
          value: orderPackageTags.map(i => i.id),
          required: false,
          formType: 'checkbox',
          attrs: {
            isButton: true
          },
          options: 'adminSetting/select?module_match=orderPackageTag'
        },
        remark: {
          label: '备注',
          value: remark,
          required: false,
          max: 500,
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          },
          width: '100%'
        },
        photos: {
          label: '验货拍照',
          value: orderGoods,
          formType: 'goodsAdditions',
          width: '100%',
          required: false
        }
      };
      if (id) {
        form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      if (showFields && showFields.length > 0) {
        for (const k in form) {
          if (form[k].show !== false && showFields.indexOf(k) === -1) {
            delete form[k];
          }
        }
      }
      this.form = form;
      this.$refs.wDialogForm.visible = true;
    },
    async open(data, showFields = [], readonly) {
      await this.setForm(data, showFields);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    },
    done(v) {
      this.$emit('done', v);
      this.$store.dispatch('cleanColumnOptions', this.module + '/select');
    },
    async getAdditionAmount(f) {
      // if(f) {
      //   if(f.model.addition_id) {
      //     const { data } = await this.$w_fun.post(`addition/amount`, {
      //       additions: [{ addition_id: f.model.addition_id, goods_quantity: f.model.goods_quantity }]
      //     })
      //     f.model.addition_amount = data ? data.amount : 0
      //   } else {
      //     f.model.addition_amount = 0
      //   }
      // }
    },
    async beforeSubmit(model) {
      if (model.photos) {
        if (this.status > 4 || this.addition_pay_status === 1) {
          delete model.photos;
        } else {
          const photos = [];
          for (const goods of model.photos) {
            photos.push(await this.$refs[`additionForm${goods.id}`][0].submit());
          }
          model.photos = photos;
        }
      }
      return model;
    }
  }
};
import "core-js/modules/es.array.push.js";
import WorkAddDialog from '../work/dialog/workAddDialog';
import OrderRefundAddDialog from '@/views/orderRefund/dialog/orderRefundAddDialog';
import RefundTable from '@/views/orderRefund/refund';
import PayTable from '@/views/orderPay/pay';
import bcmethods from '@/mixins/bcmethods';
import WorkTable from '@/views/work/index';
import OrderPackageAddDialog from '@/views/orderPackage/dialog/orderPackageAddDialog';
import CouponTable from '@/views/userCoupon/coupon.vue';
import UserMsgAddDialog from '@/views/userMsg/dialog/userMsgAddDialog';
export default {
  title: 'OrderGet',
  components: {
    CouponTable,
    WorkTable,
    WorkAddDialog,
    OrderRefundAddDialog,
    RefundTable,
    PayTable,
    OrderPackageAddDialog,
    UserMsgAddDialog
  },
  mixins: [bcmethods],
  data() {
    return {
      module: 'order',
      activeNames: ['order', 'orderGoods', 'orderPackages'],
      oldActiveNames: ['order', 'orderGoods', 'orderPackages'],
      order: {
        id: undefined,
        order_no: undefined
      },
      goodsAmount: 0,
      orderGoods: [],
      orderChanges: [],
      orderPackages: [],
      orderHistories: [],
      orderPays: [],
      orderRefunds: [],
      goodsImages: [],
      payImages: [],
      orderImages: [],
      orderPackagePhotos: [],
      formOrder: {},
      loading: false,
      form: {},
      formAction: '',
      title: '修改'
    };
  },
  computed: {
    size_() {
      return this.$store.getters['size'];
    },
    canEdit() {
      return this.order.status !== 99;
    }
  },
  created() {
    this.order.id = this.$route.query.id;
    if ((this.order.id + '').length > 10) {
      this.order.id = undefined;
      this.order.order_no = this.$route.query.id;
    }
    this.getList();
  },
  methods: {
    getList() {
      this.$w_fun.post(`${this.module}/get`, {
        id: this.order.id,
        order_no: this.order.order_no
      }).then(res => {
        this.order = res.data.detail;
        this.orderGoods = this.order.orderGoods;
        if (this.order.site_id === 1) {
          this.payImages = this.order.orderInsteadInfo ? this.order.orderInsteadInfo.pay_image || [] : [];
          this.goodsImages = this.order.orderInsteadInfo ? this.order.orderInsteadInfo.goods_image || [] : [];
        }
        this.setOrderForm(this.order);
        this.loadDataList('orderGoods');
        this.loadDataList('orderPackages');
        this.getImage();
      });
    },
    getImage() {
      this.$w_fun.post(`orderImage/index`, {
        order_id_match: this.order.id,
        limit: 0
      }).then(res => {
        this.orderImages = res.data.list;
      });
    },
    setOrderForm({
      id,
      site_text,
      user_id,
      paid_at,
      created_at,
      is_manual_purchase,
      amount,
      paid_amount,
      calc_amount,
      trans_amount,
      store_trans_amount,
      change_amount_range,
      addition_amount,
      refund,
      discount,
      origin_amount,
      goods_amount,
      fee,
      exchange_rate,
      cost,
      status,
      status_text,
      user_status,
      pay_status,
      order_no,
      address_code,
      comment,
      remark,
      user = {},
      siteAccount = {}
    }) {
      this.formOrder = {
        status: {
          label: '订单状态',
          value: status_text
        },
        user_id: {
          label: '会员专属码',
          value: user_id
        },
        created_at: {
          label: '下单时间',
          value: created_at
        },
        order_no: {
          label: '订单号',
          value: order_no,
          copy: true
        },
        phone: {
          label: '手机',
          value: user.phone,
          copy: true
        },
        paid_at: {
          label: '支付时间',
          value: paid_at
        },
        site_text: {
          label: '站点',
          value: site_text
        },
        nickname: {
          label: '昵称',
          value: user.nickname
        },
        address_code: {
          label: '地址码',
          value: address_code
        },
        change_amount_range: {
          label: '变更额度',
          value: change_amount_range,
          tooltip: '订单金额变更在多少范围内容可以自动确认'
        },
        site_account_id: {
          label: '下单账号',
          value: siteAccount && siteAccount.account_name
        },
        is_manual_purchase: {
          label: '人工下单',
          value: is_manual_purchase ? '是' : '否'
        },
        comment: {
          label: '用户留言',
          value: comment,
          width: '100%'
        },
        remark: {
          label: '备注',
          value: remark,
          width: '100%'
        },
        orderImages: {
          label: '订单图片',
          value: [],
          width: '100%',
          formType: 'orderImages'
        }
      };
    },
    handleChange(v) {
      if (this.oldActiveNames.length < v.length) {
        const f = v.filter(i => !this.oldActiveNames.includes(i));
        for (const item of f) {
          if (item !== 'order') {
            this.loadDataList(item);
          } else {
            this.getList();
          }
        }
      }
      this.oldActiveNames = v;
    },
    loadDataList(v) {
      if (this.activeNames.indexOf(v) === -1) {
        this.oldActiveNames.push(v);
        this.activeNames.push(v);
      }
      if (v === 'orderRefunds') {
        this.$nextTick(() => {
          this.$refs.refundTable && this.$refs.refundTable.getList();
        });
        return;
      }
      if (v === 'orderPays') {
        this.$nextTick(() => {
          this.$refs.payTable && this.$refs.payTable.getList();
        });
        return;
      }
      if (v === 'works') {
        this.$nextTick(() => {
          this.$refs.workTable && this.$refs.workTable.onRefresh();
        });
        return;
      }
      if (v === 'coupons') {
        this.$refs.couponTable.getList();
        return;
      }
      this.$w_fun.post(`${this.module}/${v}`, {
        id: this.order.id,
        site_id: this.order.site_id
      }).then(res => {
        this[v] = res.data.list;
        if (res.data.goodsAmount) {
          this.goodsAmount = res.data.goodsAmount;
        }
      });
    },
    uploadOrderImage() {
      this.form = {
        order_id: {
          show: false,
          value: this.order.id
        },
        images: {
          label: '订单图片',
          value: [],
          formType: 'upload',
          attrs: {
            accept: '.jpg,.png,.jpeg',
            limit: 20
          },
          type: 'array',
          required: false
        }
      };
      this.formAction = 'orderImage/add';
      this.$refs.wDialogForm.visible = true;
    },
    deleteOrderImage(row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`orderImage/delete`, {
          id: row.id
        }).then(res => {
          this.getImage();
        });
      }).catch(() => {});
    },
    editOrder() {
      this.form = {
        id: {
          show: false,
          value: this.order.id
        },
        site_account_id: {
          show: this.order.site_id > 2 && this.order.is_manual_purchase,
          label: '采购账号',
          value: this.order.site_account_id,
          formType: 'select',
          options: '/siteAccount/select?site_id_match=' + this.order.site_id
        },
        remark: {
          label: '备注',
          value: this.order.remark,
          required: false,
          max: 500,
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          }
        }
      };
      this.formAction = 'order/edit';
      this.$refs.wDialogForm.visible = true;
    },
    editTransAmount() {
      this.form = {
        id: {
          show: false,
          value: this.order.id
        },
        trans_amount: {
          label: '运费',
          value: this.order.trans_amount,
          required: false,
          formType: 'amount',
          show: (this.order.status === 1 || this.order.status === 0) && this.order.site_id === 2,
          tooltip: this.order.site_id === 2 ? '采购时的运费，修改运费需要用户确认，如果是货到付款运费请采购后找用户补' : ''
        }
      };
      this.formAction = 'order/edit';
      this.$refs.wDialogForm.visible = true;
    },
    toCancel() {
      this.form = {
        id: {
          show: false,
          value: this.order.id
        },
        reason: {
          label: '取消原因',
          value: '',
          max: 500,
          formType: 'textarea'
        }
      };
      this.formAction = 'order/cancel';
      this.$refs.wDialogForm.visible = true;
    },
    auctionsPay() {
      this.form = {
        id: {
          show: false,
          value: this.order.id
        },
        freeze_amount: {
          label: '预授权金额',
          value: this.order.freeze_amount,
          readonly: true
        },
        amount: {
          label: '订单金额',
          value: this.order.amount,
          attrs: {
            disabled: true
          }
        },
        desc: {
          label: '说明',
          value: '扣除核算金额后，剩余部分会解冻，如果核算金额超过授权金额，会全额扣款，差额可以手动添加支付单',
          readonly: true
        }
      };
      this.formAction = 'order/auctionsPay';
      this.$refs.wDialogForm.visible = true;
    },
    async setPackageForm(row) {
      await this.$refs.orderPackageAddDialog.open(row, ['order_goods_id', 'third_order_no', 'tracking_company', 'tracking_no', 'from_address', 'purchase_at', 'together_goods_ids', 'status', 'remark']);
    },
    editGoods(row) {
      let additionPay = false;
      for (const item of this.orderPackages) {
        if (item.addition_pay_status === 1) {
          for (const g of item.orderGoods) {
            if (g.id === row.id) {
              additionPay = true;
            }
          }
        }
      }
      this.form = {
        id: {
          show: false,
          value: row.id
        },
        order_id: {
          show: false,
          value: row.order_id
        },
        thumb: {
          label: '图片',
          value: row.thumb ? [row.thumb] : [],
          formType: 'upload',
          attrs: {
            accept: '.jpg,.png,.jpeg'
          },
          required: false
        },
        title: {
          label: '名称',
          value: row.title,
          width: '45%'
        },
        link: {
          label: '链接',
          value: row.link,
          required: false,
          width: '45%'
        },
        price: {
          value: row.price,
          label: '单价',
          formType: 'number',
          attrs: {
            append: '円'
          },
          width: '45%',
          readonly: this.order.status > 1
        },
        quantity: {
          value: row.quantity,
          label: '数量',
          formType: 'number',
          attrs: {
            step: 1
          },
          width: '45%',
          readonly: this.order.status > 1
        },
        addition_id: {
          value: row.addition_id,
          label: '验货拍照',
          formType: 'select',
          options: '/addition/select?position_type_byte=1,2',
          required: false,
          methods: {
            change: async v => {
              await this.getAdditionAmount(this.getForm());
            }
          },
          width: '45%',
          readonly: additionPay
        },
        addition_comment: {
          value: row.addition_comment,
          label: '拍照备注',
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          },
          max: 500,
          required: false,
          width: '45%',
          readonly: additionPay
        },
        goods_quantity: {
          value: row.goods_quantity,
          label: '拍照商品数量',
          formType: 'number',
          max: 1000,
          required: false,
          methods: {
            change: async v => {
              await this.getAdditionAmount(this.getForm());
            }
          },
          width: '45%',
          readonly: additionPay
        },
        addition_amount: {
          value: row.addition_amount,
          label: '拍照费用',
          formType: 'amount',
          required: false,
          width: '45%',
          readonly: additionPay
        },
        addition_amount_comment: {
          show: m => m.addition_amount > 0,
          value: row.addition_amount_comment,
          label: '拍照费用说明',
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          },
          max: 500,
          required: false,
          width: '45%',
          readonly: additionPay
        },
        desc: {
          value: '变更商品金额需要用户确认',
          label: '说明',
          readonly: true
        }
      };
      if (row && row.id) {
        this.form.id = {
          show: false,
          value: row.id
        };
        this.formAction = 'orderGoods/edit';
      } else {
        this.formAction = 'orderGoods/add';
      }
      this.$refs.wDialogForm.visible = true;
    },
    editInsteadInfo() {
      this.form = {
        order_id: {
          show: false,
          value: this.order.id
        },
        instead_pay_type_id: {
          label: '支付方式',
          value: this.order.orderInsteadInfo && this.order.orderInsteadInfo.instead_pay_type_id,
          formType: 'radio',
          options: 'insteadPayType/select',
          attrs: {
            isButton: true
          }
        },
        goods_image: {
          label: '商品图片',
          value: this.goodsImages,
          formType: 'upload',
          attrs: {
            accept: '.jpg,.png,.jpeg',
            limit: 20
          },
          type: 'array'
        },
        pay_image: {
          label: '付款码图片',
          value: this.payImages,
          formType: 'upload',
          attrs: {
            accept: '.jpg,.png,.jpeg',
            limit: 20
          },
          type: 'array'
        }
      };
      this.formAction = 'order/editInsteadInfo';
      this.$refs.wDialogForm.visible = true;
    },
    toDelGoods(row) {
      this.$confirm('确认删除吗？删除会变更订单金额，需要用户确认', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`orderGoods/edit`, {
          id: row.id,
          title: row.title,
          link: row.link,
          price: row.price,
          quantity: 0
        }).then(res => {
          this.loadDataList('orderChanges');
        });
      }).catch(() => {});
    },
    toDelGoodsChange(row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`orderGoods/delChange`, {
          id: row.id
        }).then(res => {
          this.loadDataList('orderChanges');
        });
      }).catch(() => {});
    },
    toSendChange(row) {
      this.form = {
        id: {
          show: false,
          value: row.id
        },
        remark: {
          label: '变动说明',
          value: '',
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          }
        }
      };
      this.formAction = 'order/sendChange';
      this.$refs.wDialogForm.visible = true;
    },
    toConfirmChange(row) {
      this.$confirm('确认帮用户确认吗？', '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`order/confirmChange`, {
          id: row.id
        }).then(res => {
          this.getList();
          this.loadDataList('orderChanges');
          this.loadDataList('orderGoods');
        });
      }).catch(() => {});
    },
    toCancelChange(row) {
      this.$confirm('确认取消吗？', '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`order/cancelChange`, {
          id: row.id
        }).then(res => {
          this.loadDataList('orderChanges');
          this.getList();
        });
      }).catch(() => {});
    },
    async addTogether(row) {
      await this.$refs.orderPackageAddDialog.open(row, ['together_goods_ids', 'remark']);
    },
    toOrder(id) {
      const {
        href
      } = this.$router.resolve({
        name: 'OrderGet',
        query: {
          id
        }
      });
      window.open(href, '_blank');
    },
    async delTogether(row) {
      const confirm = await this.$confirm('确定要取消同捆吗？', '提示', {
        type: 'warning'
      }).catch(() => {});
      if (!confirm) {
        return;
      }
      this.$w_fun.post(`orderPackage/cancelTogether`, {
        id: row.id
      }).then(res => {
        this.loadDataList('orderPackages');
      });
    },
    addPackage() {
      this.setPackageForm({
        order_id: this.order.id,
        purchase_at: null,
        allGoods: this.orderGoods,
        orderGoods: this.orderPackages.length > 0 ? [] : this.orderGoods,
        status: 2
      });
    },
    async editPackageGoods(row) {
      await this.$refs.orderPackageAddDialog.open({
        ...row,
        allGoods: this.orderGoods
      }, ['order_goods_id', 'remark']);
    },
    async deletePackage(row) {
      const confirm = await this.$confirm('确认删除吗？', '提示', {
        type: 'warning'
      }).catch(() => {});
      if (!confirm) {
        return;
      }
      this.$w_fun.post(`orderPackage/delete`, {
        id: row.id
      }).then(res => {
        this.loadDataList('orderPackages');
        this.getList();
      });
    },
    async editAddition(row) {
      await this.$refs.orderPackageAddDialog.open({
        ...row,
        allGoods: this.orderGoods
      }, ['photos']);
    },
    async getAdditionAmount(f) {
      if (f) {
        if (f.model.addition_id) {
          const {
            data
          } = await this.$w_fun.post(`addition/amount`, {
            additions: [{
              addition_id: f.model.addition_id,
              goods_quantity: f.model.goods_quantity
            }]
          });
          f.model.addition_amount = data ? data.amount : 0;
        } else {
          f.model.addition_amount = 0;
        }
      }
    },
    addWork(row) {
      this.$refs.workAddDialog.open(row);
    },
    toPay(row) {
      this.form = {
        id: {
          show: false,
          value: row.id
        },
        fields: {
          label: '支付内容',
          value: [],
          formType: 'checkbox',
          options: []
        }
      };
      if (row.trans_amount > 0 && row.trans_pay_status === 0) {
        this.form.fields.options.push({
          label: '运费：' + row.trans_amount + '円',
          value: 'trans_amount'
        });
      }
      if (row.addition_amount > 0 && row.addition_pay_status === 0) {
        this.form.fields.options.push({
          label: '验货拍照：' + row.addition_amount + '円',
          value: 'addition_amount'
        });
      }
      this.formAction = 'orderPackage/toPay';
      this.$refs.wDialogForm.visible = true;
    },
    async editPurchase(row) {
      await this.$refs.orderPackageAddDialog.open({
        ...row,
        allGoods: this.orderGoods
      }, ['status', 'third_order_no', 'purchase_at', 'tracking_from_time_at', 'remark']);
    },
    async editTrans(row) {
      await this.$refs.orderPackageAddDialog.open({
        ...row,
        allGoods: this.orderGoods
      }, ['status', 'tracking_company', 'tracking_no', 'from_address', 'trans_amount', 'remark']);
    },
    async editPackage(row) {
      await this.$refs.orderPackageAddDialog.open({
        ...row,
        allGoods: this.orderGoods
      }, ['status', 'package_no', 'size_type', 'weight', 'shelf_no', 'remark', 'order_package_tags']);
    },
    addPay() {
      this.$refs.payTable.addPay({
        order_id: {
          show: false,
          value: this.order.id
        },
        order_package_id: {
          label: '包裹',
          value: undefined,
          show: this.order.status >= 4,
          formType: 'radio',
          options: this.orderPackages.map(i => {
            return {
              label: i.package_no,
              value: i.id
            };
          })
        }
      }, this.order.exchange_rate);
    },
    beforeSubmit(data) {
      return data;
    },
    formDone() {
      switch (this.formAction) {
        case 'order/edit':
        case 'order/auctionsPay':
        case 'order/sendChange':
          this.getList();
          this.loadDataList('orderGoods');
          this.loadDataList('orderChanges');
          break;
        case 'orderGoods/edit':
        case 'orderGoods/add':
          this.loadDataList('orderGoods');
          this.loadDataList('orderChanges');
          break;
        case 'orderPackage/add':
          this.loadDataList('orderGoods');
          this.loadDataList('orderPackages');
          break;
        case 'orderPackage/edit':
        case 'orderPackage/toPay':
        case 'orderPackage/addTogether':
          this.loadDataList('orderPackages');
          break;
        case 'orderRefund/verify':
        case 'orderRefund/add':
          this.loadDataList('orderRefunds');
          break;
        case 'orderPay/add':
          this.loadDataList('orderPays');
          break;
        case 'order/cancel':
          this.getList();
          this.loadDataList('orderRefunds');
          break;
        case 'orderImage/add':
          this.getImage();
          break;
        case 'order/editInsteadInfo':
          this.getList();
          break;
      }
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};
import WorkTypeAddDialog from '@/views/workType/dialog/workTypeAddDialog';
export default {
  name: 'WorkAddDialog',
  components: {
    WorkTypeAddDialog
  },
  data() {
    return {
      module: 'work',
      formAction: '',
      form: {},
      readonly: false,
      changeDesc: false
    };
  },
  methods: {
    setForm({
      id,
      desc,
      handle_admin_department_id,
      handle_admin_id,
      obj_id,
      work_type_id,
      priority_id,
      is_user_visible = 0,
      obj_title,
      user_desc,
      obj_type_id
    }) {
      this.changeDesc = !!desc;
      this.form = {
        desc: {
          label: '简要描述',
          value: desc,
          max: 500,
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          },
          methods: {
            change: v => {
              this.changeDesc = true;
            }
          }
        },
        work_type_id: {
          label: '工单类型',
          value: work_type_id,
          formType: 'select',
          options: 'workType/select',
          attrs: {
            quickAdd: () => {
              this.$refs.workTypeAddDialog.open({});
            }
          },
          methods: {
            change: v => {
              if (!this.changeDesc || !this.getForm().model.desc) {
                for (const item of this.getForm().options.work_type_id) {
                  if (item.value === v && item.desc) {
                    this.getForm().model.desc = item.desc;
                  }
                }
              }
            }
          }
        },
        obj_type_id: {
          label: '数据类型',
          show: !obj_title && !id,
          value: obj_type_id,
          formType: 'radio',
          options: {
            url: '/work/create',
            key: 'objType'
          },
          attrs: {
            isButton: true
          },
          methods: {
            change: v => {
              for (const item of this.getForm().formItems) {
                if (item.prop === 'obj_id') {
                  if (v < 99) {
                    item.show = true;
                    item.options.data.obj_type_id = v;
                    this.getForm().refreshOptions('obj_id');
                  } else {
                    item.show = false;
                  }
                }
              }
            }
          }
        },
        obj_id: {
          show: !id && !obj_title,
          label: '关联数据',
          value: obj_id,
          formType: 'remote',
          options: {
            url: '/work/objSelect',
            data: {
              obj_type_id: obj_type_id
            }
          },
          readonlyValue: obj_title
        },
        obj_title: {
          label: '关联数据',
          value: obj_title,
          show: !!(obj_title || id),
          readonly: true
        },
        handle_admin_id: {
          label: '接单人',
          value: handle_admin_id,
          formType: 'select',
          options: '/admin/select',
          required: false
        },
        handle_admin_department_id: {
          label: '接单部门',
          value: handle_admin_department_id,
          formType: 'treeRadioFree',
          options: '/adminDepartment/selectTree',
          required: false
        },
        priority_id: {
          label: '优先级',
          value: priority_id,
          formType: 'radio',
          options: {
            url: '/work/create',
            key: 'priority'
          },
          attrs: {
            isButton: true
          }
        },
        is_user_visible: {
          show: false,
          label: '用户是否可见',
          value: is_user_visible,
          formType: 'boolean',
          attrs: {
            isButton: true
          }
        },
        user_desc: {
          label: '用户显示内容',
          value: user_desc,
          max: 255,
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          },
          show: m => m.is_user_visible
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-collapse", {
    attrs: {
      size: _vm.size_
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.activeNames,
      callback: function ($$v) {
        _vm.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, [_c("el-collapse-item", {
    attrs: {
      title: "基础信息",
      name: "orderCombine"
    }
  }, [_c("w-form", {
    ref: "wForm",
    attrs: {
      title: "订单信息",
      form: _vm.formOrderCombine,
      readonly: true,
      itemWidth: "32%",
      labelWidth: _vm.size_ === "mini" ? "110px" : "130px"
    }
  }), _vm.orderCombine.status === 0 ? _c("el-button", {
    staticClass: "ml40",
    attrs: {
      type: "primary",
      size: _vm.size_
    },
    on: {
      click: _vm.toHandle
    }
  }, [_vm._v("受理")]) : _vm._e(), _vm.canEdit ? _c("el-button", {
    staticClass: "ml40",
    attrs: {
      type: "primary",
      size: _vm.size_
    },
    on: {
      click: _vm.editOrderCombine
    }
  }, [_vm._v("修改")]) : _vm._e(), _c("el-button", {
    staticClass: "ml40",
    attrs: {
      type: "primary",
      size: _vm.size_
    },
    on: {
      click: function ($event) {
        return _vm.$refs.userMsgAddDialog.open({
          user_id: [_vm.orderCombine.user_id],
          route: {
            name: "user_order_detail",
            params: {
              order_no: _vm.orderCombine.order_no,
              tab: 5
            }
          }
        });
      }
    }
  }, [_vm._v("发送站内信")]), _vm.canEdit ? _c("el-button", {
    staticClass: "ml40",
    attrs: {
      type: "danger",
      size: _vm.size_
    },
    on: {
      click: _vm.toCancel
    }
  }, [_vm._v("取消")]) : _vm._e(), _vm.orderCombine.status === 6 ? _c("el-button", {
    staticClass: "ml40",
    attrs: {
      type: "danger",
      size: _vm.size_
    },
    on: {
      click: _vm.rollCancel
    }
  }, [_vm._v("撤回取消")]) : _vm._e(), _c("p", {
    staticClass: "w100 p5 b mt10 border-f1f1f1-top"
  }, [_vm._v("收货地址:")]), _vm.canEdit ? _c("el-button", {
    staticClass: "ml40",
    attrs: {
      type: "primary",
      size: _vm.size_
    },
    on: {
      click: function ($event) {
        return _vm.editAddress({});
      }
    }
  }, [_vm._v("新增")]) : _vm._e(), _c("el-table", {
    staticClass: "ml40",
    attrs: {
      data: _vm.userAddressList,
      "show-header": false,
      size: _vm.size_,
      stripe: "",
      "max-height": "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "consignee",
      label: "收件人",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.consignee,
            expression: "scope.row.consignee"
          }],
          staticClass: "omit cursor"
        }, [_vm._v(_vm._s(scope.row.consignee))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "phone",
      label: "电话",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.phone,
            expression: "scope.row.phone"
          }],
          staticClass: "omit cursor"
        }, [_vm._v(_vm._s(scope.row.phone))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "id_card_no",
      label: "身份证",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.id_card_no,
            expression: "scope.row.id_card_no"
          }],
          staticClass: "omit cursor"
        }, [_vm._v(_vm._s(scope.row.id_card_no))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "location",
      label: "区域",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.location,
            expression: "scope.row.location"
          }],
          staticClass: "omit cursor"
        }, [_vm._v(_vm._s(scope.row.location))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "detail",
      label: "详细地址",
      width: "240",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.detail,
            expression: "scope.row.detail"
          }],
          staticClass: "omit cursor"
        }, [_vm._v(_vm._s(scope.row.detail))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "id_card_no",
      label: "身份证正面",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-image", {
          staticClass: "w-image-40",
          attrs: {
            src: scope.row.id_card_front,
            "preview-src-list": [scope.row.id_card_front, scope.row.id_card_back],
            fit: "contain"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "id_card_no",
      label: "身份证反面",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-image", {
          staticClass: "w-image-40",
          attrs: {
            src: scope.row.id_card_back,
            "preview-src-list": [scope.row.id_card_front, scope.row.id_card_back],
            "z-index": 1,
            fit: "contain"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: 100 * _vm.$store.getters["sizeRate"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button-group", [_vm.canEdit ? _c("el-button", {
          attrs: {
            type: "primary",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.editAddress(scope.row);
            }
          }
        }, [_vm._v("编辑")]) : _vm._e(), _vm.canEdit ? _c("el-button", {
          attrs: {
            type: "danger",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.deleteAddress(scope.row);
            }
          }
        }, [_vm._v("删除")]) : _vm._e()], 1)];
      }
    }])
  })], 1)], 1), _c("el-collapse-item", {
    attrs: {
      title: "增值服务",
      name: "orderCombineAdditions"
    }
  }, [_vm.canEdit ? _c("el-button", {
    attrs: {
      size: _vm.size_,
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.editAddition({});
      }
    }
  }, [_vm._v("新增")]) : _vm._e(), _c("el-table", {
    staticClass: "w100",
    attrs: {
      data: _vm.orderCombineAdditions,
      size: _vm.size_,
      border: "",
      stripe: "",
      "max-height": "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "title",
      label: "名称"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.addition && scope.row.addition.title) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "comment",
      label: "备注"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: 100 * _vm.$store.getters["sizeRate"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button-group", [_vm.canEdit ? _c("el-button", {
          attrs: {
            type: "primary",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.editAddition(scope.row);
            }
          }
        }, [_vm._v("编辑")]) : _vm._e(), _vm.canEdit ? _c("el-button", {
          attrs: {
            type: "danger",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.deleteAddition(scope.row);
            }
          }
        }, [_vm._v("删除")]) : _vm._e()], 1)];
      }
    }])
  })], 1)], 1), _c("el-collapse-item", {
    attrs: {
      title: "捆包",
      name: "orderCombinePackages"
    }
  }, [_vm.canEdit ? _c("el-button", {
    attrs: {
      size: _vm.size_,
      type: "primary"
    },
    on: {
      click: _vm.addOrderCombinePackage
    }
  }, [_vm._v("新增捆包")]) : _vm._e(), _c("el-table", {
    staticClass: "w100",
    attrs: {
      data: _vm.orderCombinePackages,
      size: _vm.size_,
      border: "",
      stripe: "",
      "max-height": "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "名称",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" 捆包" + _vm._s(scope.$index + 1) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "package_no",
      label: "包裹号",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.package_no,
            expression: "scope.row.package_no"
          }],
          staticClass: "omit cursor"
        }, [_vm._v(_vm._s(scope.row.package_no))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "tracking_no",
      label: "物流单号",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.tracking_no,
            expression: "scope.row.tracking_no"
          }],
          staticClass: "omit cursor"
        }, [_vm._v(_vm._s(scope.row.tracking_no))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "orderCombineAddressText",
      label: "收件人"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "trans_amount",
      label: "运费",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "addition_amount",
      label: "增值服务费",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "weight",
      label: "重量(kg)",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "size_text",
      label: "尺寸",
      width: "140"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "v_w",
      label: "体积重",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "transFee.title",
      label: "运输渠道",
      width: "120",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "orderCombinePackageAdditions",
      label: "增值服务",
      "min-width": "470"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-table", {
          attrs: {
            data: scope.row.orderCombinePackageAdditions,
            size: _vm.size_,
            stripe: ""
          }
        }, [_c("el-table-column", {
          attrs: {
            prop: "weight",
            label: "类型",
            width: "100"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function ({
              row
            }) {
              return [_vm._v(" " + _vm._s(row.addition && row.addition.title) + " ")];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            prop: "amount",
            label: "金额",
            width: "80"
          }
        }), _c("el-table-column", {
          attrs: {
            prop: "comment",
            label: "用户备注",
            "show-overflow-tooltip": "",
            "min-width": "120"
          }
        }), _c("el-table-column", {
          attrs: {
            prop: "amount_comment",
            label: "金额说明",
            "show-overflow-tooltip": "",
            "min-width": "120"
          }
        })], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "备注"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: 100 * _vm.$store.getters["sizeRate"],
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button-group", [(_vm.orderCombine.status === 6 || _vm.orderCombine.status === 99) && !scope.row.stored_at && _vm.orderCombine.pack_start_at ? _c("el-button", {
          attrs: {
            type: "warning",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.reStore(scope.row);
            }
          }
        }, [_vm._v("捆包入库")]) : _vm._e(), _vm.canEdit ? _c("el-button", {
          attrs: {
            type: "primary",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.editOrderCombinePackage(scope.row);
            }
          }
        }, [_vm._v("编辑")]) : _vm._e(), _vm.canEdit ? _c("el-button", {
          attrs: {
            type: "danger",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.delOrderCombinePackage(scope.row);
            }
          }
        }, [_vm._v("删除")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c("div", {
    staticClass: "p5 w100 tr f16 b"
  }, [_vm.orderCombine.fee ? _c("span", [_vm._v("手续费:" + _vm._s(_vm.orderCombine.fee))]) : _vm._e(), _vm.orderCombine.addition_amount ? _c("span", {
    staticClass: "ml30"
  }, [_vm._v("增值服务:" + _vm._s(_vm.orderCombine.addition_amount))]) : _vm._e(), _vm.orderCombine.trans_amount ? _c("span", {
    staticClass: "ml30"
  }, [_vm._v("运费:" + _vm._s(_vm.orderCombine.trans_amount))]) : _vm._e(), _vm.orderCombine.other_amount ? _c("span", {
    staticClass: "ml30"
  }, [_vm._v(_vm._s(_vm.orderCombine.other_amount_title) + ":" + _vm._s(_vm.orderCombine.other_amount))]) : _vm._e(), _vm.orderCombine.discount ? _c("span", {
    staticClass: "ml30"
  }, [_vm._v("优惠:-" + _vm._s(_vm.orderCombine.discount))]) : _vm._e(), _vm.orderCombine.refund ? _c("span", {
    staticClass: "ml30"
  }, [_vm._v("退款:-" + _vm._s(_vm.orderCombine.refund))]) : _vm._e(), _c("span", {
    staticClass: "ml30"
  }, [_vm._v("总金额:"), _c("span", {
    staticClass: "red f18"
  }, [_vm._v(_vm._s(_vm.orderCombine.amount))])]), _vm.orderCombine.paid_amount ? _c("span", {
    staticClass: "ml30"
  }, [_vm._v("已支付:"), _c("span", {
    staticClass: "green f18"
  }, [_vm._v(_vm._s(_vm.orderCombine.paid_amount))])]) : _vm._e(), _vm.canEdit && _vm.orderCombine.pay_status !== 1 ? _c("el-button", {
    staticClass: "ml5 mr20",
    attrs: {
      type: "warning",
      size: _vm.size_
    },
    on: {
      click: _vm.toPay
    }
  }, [_vm._v("核算")]) : _vm._e()], 1)], 1), _c("el-collapse-item", {
    attrs: {
      title: "申报信息",
      name: "orderCombineReports"
    }
  }, [_vm.canEdit ? _c("el-button", {
    attrs: {
      type: "primary",
      size: _vm.size_
    },
    on: {
      click: function ($event) {
        return _vm.editReport({});
      }
    }
  }, [_vm._v("新增")]) : _vm._e(), _c("el-table", {
    staticClass: "w100",
    attrs: {
      data: _vm.orderCombineReports,
      size: _vm.size_,
      border: "",
      stripe: "",
      "max-height": "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "分类"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.goodsCat && scope.row.goodsCat.title,
            expression: "scope.row.goodsCat && scope.row.goodsCat.title"
          }],
          staticClass: "mr20"
        }, [_vm._v(_vm._s(scope.row.goodsCat && scope.row.goodsCat.title))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "分类其他"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.goods_cat_id ? "" : scope.row.goods_cat_other,
            expression: "scope.row.goods_cat_id ? '' : scope.row.goods_cat_other"
          }],
          staticClass: "mr20"
        }, [_vm._v(_vm._s(scope.row.goods_cat_id ? "" : scope.row.goods_cat_other))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "数量"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.quantity,
            expression: "scope.row.quantity"
          }]
        }, [_vm._v(_vm._s(scope.row.quantity))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.amount,
            expression: "scope.row.amount"
          }]
        }, [_vm._v(_vm._s(scope.row.amount))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: 100 * _vm.$store.getters["sizeRate"],
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button-group", [_vm.canEdit ? _c("el-button", {
          attrs: {
            type: "primary",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.editReport(scope.row);
            }
          }
        }, [_vm._v("编辑")]) : _vm._e(), _vm.canEdit ? _c("el-button", {
          attrs: {
            type: "danger",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.delReport(scope.row);
            }
          }
        }, [_vm._v("删除")]) : _vm._e()], 1)];
      }
    }])
  })], 1)], 1), _c("el-collapse-item", {
    attrs: {
      title: "包裹",
      name: "orderPackages"
    }
  }, [_c("el-table", {
    staticClass: "w100",
    attrs: {
      data: _vm.orderPackages,
      size: _vm.size_,
      "summary-method": _vm.getPackageSummaries,
      border: "",
      stripe: "",
      "max-height": "400",
      "show-summary": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      width: "50",
      label: "序号"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "package_no",
      label: "包裹编码",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      property: "orderGoods",
      label: "商品"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return _vm._l(scope.row.orderGoods, function (goods) {
          return _c("el-tooltip", {
            key: goods.id,
            attrs: {
              content: `${goods.third_goods_id || goods.title}，单价：${goods.price}，数量：${goods.quantity}，金额：${goods.amount}`
            }
          }, [_c("el-image", {
            staticClass: "w-image-40",
            attrs: {
              src: goods.thumb,
              fit: "contain",
              "preview-src-list": scope.row.orderGoods.map(v => v.thumb)
            }
          })], 1);
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "tracking_no",
      label: "日本国内快递号"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "shelf_no",
      label: "货架号",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "status_text",
      label: "状态",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "weight",
      label: "重量(kg)",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "size_type_text",
      label: "包裹大小",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "stored_at",
      label: "入库时间",
      width: "140"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: (_vm.orderCombine.status === 99 ? 80 : 1) * _vm.$store.getters["sizeRate"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button-group", [_vm.orderCombine.status === 99 && !scope.row.is_re_store ? _c("el-button", {
          attrs: {
            type: "warning",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.reStorePackage(scope.row);
            }
          }
        }, [_vm._v("重新入库")]) : _vm._e()], 1)];
      }
    }])
  })], 1)], 1), _c("el-collapse-item", {
    attrs: {
      title: "照片",
      name: "photos"
    }
  }, [_c("div", {
    staticClass: "w100"
  }, _vm._l(_vm.photos, function (photo, index) {
    return _c("el-image", {
      key: index,
      staticClass: "w-image-70 ml5",
      attrs: {
        src: photo.thumb,
        "preview-src-list": _vm.photos.map(i => i.thumb),
        fit: "contain"
      }
    });
  }), 1)]), _c("el-collapse-item", {
    attrs: {
      title: "支付记录",
      name: "orderPays"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: _vm.size_
    },
    on: {
      click: _vm.addPay
    }
  }, [_vm._v("新增")]), _c("PayTable", {
    ref: "payTable",
    attrs: {
      query: {
        order_combine_id: _vm.orderCombine.id,
        limit: 0
      }
    },
    on: {
      done: function ($event) {
        return _vm.loadDataList("orderRefunds");
      }
    }
  })], 1), _c("el-collapse-item", {
    attrs: {
      title: "退款记录",
      name: "orderRefunds"
    }
  }, [_c("RefundTable", {
    ref: "refundTable",
    attrs: {
      query: {
        order_combine_id: _vm.orderCombine.id,
        limit: 0
      }
    },
    on: {
      done: function ($event) {
        return _vm.loadDataList("orderPays");
      }
    }
  })], 1), _vm.orderCombine.discount > 0 ? _c("el-collapse-item", {
    attrs: {
      title: "优惠券",
      name: "coupons"
    }
  }, [_c("CouponTable", {
    ref: "couponTable",
    attrs: {
      query: {
        order_combine_id_match: _vm.orderCombine.id,
        limit: 0
      }
    },
    on: {
      done: function ($event) {
        return _vm.loadDataList("coupons");
      }
    }
  })], 1) : _vm._e(), _c("el-collapse-item", {
    attrs: {
      title: "工单",
      name: "works"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: _vm.size_
    },
    on: {
      click: _vm.addWork
    }
  }, [_vm._v("新增")]), _c("WorkTable", {
    ref: "workTable",
    attrs: {
      query: {
        obj_type_id_match: 1,
        obj_id_match: _vm.orderCombine.id
      },
      "max-height": "300px"
    },
    on: {
      done: function ($event) {
        return _vm.loadDataList("works");
      }
    }
  })], 1), _c("el-collapse-item", {
    attrs: {
      title: "操作历史",
      name: "histories"
    }
  }, [_c("el-table", {
    staticClass: "w100",
    attrs: {
      data: _vm.histories,
      size: _vm.size_,
      border: "",
      stripe: "",
      "max-height": "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "created_at",
      label: "日期",
      width: "140"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "admin_id",
      label: "操作人",
      width: "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.admin_id === -1 ? "用户" : scope.row.admin_id === 0 ? "系统" : scope.row.admin.nickname) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "comment",
      label: "内容"
    }
  })], 1)], 1)], 1), _c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      title: _vm.title,
      form: _vm.form,
      action: _vm.formAction,
      width: "80%",
      beforeSubmit: _vm.beforeSubmit,
      labelWidth: _vm.labelWidth
    },
    on: {
      done: _vm.formDone
    },
    scopedSlots: _vm._u([{
      key: "packageIds",
      fn: function ({
        model
      }) {
        return [_c("w-table", {
          ref: "wPackagesSelectTable",
          attrs: {
            operates: {
              del: false,
              look: false,
              add: false,
              edit: false,
              multiDel: false
            },
            actions: {
              index: "orderCombine/orderPackages?id=" + _vm.orderCombine.id,
              columns: [{
                label: "包裹编码",
                v: "package_no"
              }, {
                label: "重量",
                v: "weight"
              }, {
                label: "大小",
                v: "size_text"
              }]
            },
            pagination: false,
            asyncSummary: false,
            selectable: true,
            "max-height": "500"
          },
          on: {
            "selection-change": v => {
              model.package_ids = v.map(i => i.id);
            }
          }
        })];
      }
    }, {
      key: "additionAmount",
      fn: function ({
        model
      }) {
        return [_c("span", [_vm._v(_vm._s(model.addition_amount) + "円")])];
      }
    }, {
      key: "addition",
      fn: function ({
        model
      }) {
        return [_vm.orderCombine.status <= 2 ? _c("el-button", {
          attrs: {
            size: _vm.size_,
            type: "primary"
          },
          on: {
            click: _vm.addOrderCombinePackageAddition
          }
        }, [_vm._v("新增")]) : _vm._e(), _c("el-table", {
          staticClass: "w100",
          attrs: {
            data: model.orderCombinePackageAdditions,
            size: _vm.size_,
            border: "",
            stripe: "",
            "max-height": "400"
          }
        }, [_c("el-table-column", {
          attrs: {
            prop: "title",
            label: "名称",
            width: "120",
            "show-overflow-tooltip": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.addition && scope.row.addition.title) + " ")];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            prop: "goods_quantity",
            label: "商品数量",
            width: "120"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_c("el-input", {
                attrs: {
                  size: _vm.size_,
                  type: "number",
                  step: 1
                },
                on: {
                  change: function ($event) {
                    return _vm.changePackageAddition(scope.row);
                  }
                },
                model: {
                  value: scope.row.goods_quantity,
                  callback: function ($$v) {
                    _vm.$set(scope.row, "goods_quantity", $$v);
                  },
                  expression: "scope.row.goods_quantity"
                }
              })];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            prop: "amount",
            label: "金额",
            width: "180"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_c("el-input", {
                attrs: {
                  size: _vm.size_,
                  type: "number",
                  step: 1
                },
                on: {
                  change: function ($event) {
                    return _vm.changePackageAddition({});
                  }
                },
                scopedSlots: _vm._u([{
                  key: "append",
                  fn: function () {
                    return [_vm._v("円")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: scope.row.amount,
                  callback: function ($$v) {
                    _vm.$set(scope.row, "amount", $$v);
                  },
                  expression: "scope.row.amount"
                }
              })];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            prop: "comment",
            label: "用户留言"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_c("el-input", {
                attrs: {
                  size: _vm.size_,
                  maxlength: "500"
                },
                model: {
                  value: scope.row.comment,
                  callback: function ($$v) {
                    _vm.$set(scope.row, "comment", $$v);
                  },
                  expression: "scope.row.comment"
                }
              })];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            prop: "amount_comment",
            label: "费用说明"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_c("el-input", {
                attrs: {
                  size: _vm.size_,
                  maxlength: "500"
                },
                model: {
                  value: scope.row.amount_comment,
                  callback: function ($$v) {
                    _vm.$set(scope.row, "amount_comment", $$v);
                  },
                  expression: "scope.row.amount_comment"
                }
              })];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            label: "操作",
            width: 60 * _vm.$store.getters["sizeRate"]
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_vm.orderCombine.status <= 2 ? _c("el-button", {
                attrs: {
                  type: "danger",
                  size: _vm.size_,
                  icon: "el-icon-delete"
                },
                on: {
                  click: function ($event) {
                    return _vm.deletePackageAddition(scope.$index);
                  }
                }
              }) : _vm._e()];
            }
          }], null, true)
        })], 1)];
      }
    }, {
      key: "print",
      fn: function () {
        return [_c("el-button", {
          attrs: {
            type: "primary"
          },
          on: {
            click: _vm.printPackageNo
          }
        }, [_vm._v("打印入库标签")])];
      },
      proxy: true
    }])
  }), _c("w-dialog-form", {
    ref: "wSecondDialogForm",
    attrs: {
      title: _vm.title,
      form: _vm.formSecond,
      action: _vm.formActionSecond,
      width: _vm.$w_fun.isMobile() ? "100%" : "60%",
      labelWidth: "140px"
    },
    on: {
      done: _vm.secondDone
    }
  }), _c("UserMsgAddDialog", {
    ref: "userMsgAddDialog"
  }), _c("OrderRefundAddDialog", {
    ref: "orderRefundAddDialog",
    on: {
      done: function ($event) {
        return _vm.loadDataList("orderRefunds");
      }
    }
  }), _c("WorkAddDialog", {
    ref: "workAddDialog",
    on: {
      done: function ($event) {
        return _vm.loadDataList("works");
      }
    }
  }), _vm.orderCombine.user_id ? _c("PrintRecipt", {
    ref: "printRecipt",
    staticClass: "printRecipt"
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      title: "添加/编辑",
      form: _vm.form,
      action: _vm.formAction,
      width: _vm.$w_fun.isMobile() ? "100%" : "60%",
      labelWidth: "150px",
      itemWidth: "45%"
    },
    on: {
      done: _vm.done
    },
    scopedSlots: _vm._u([{
      key: "transFeeItems",
      fn: function ({
        model
      }) {
        return [_c("el-table", {
          staticClass: "w100",
          attrs: {
            data: model.transFeeItems,
            size: _vm.size_,
            border: "",
            stripe: ""
          }
        }, [_c("el-table-column", {
          attrs: {
            prop: "weight",
            label: "重量(kg)"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_c("el-input", {
                attrs: {
                  size: _vm.size_,
                  type: "number"
                },
                model: {
                  value: scope.row.weight,
                  callback: function ($$v) {
                    _vm.$set(scope.row, "weight", $$v);
                  },
                  expression: "scope.row.weight"
                }
              })];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            prop: "title",
            label: "金额"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_c("el-input", {
                attrs: {
                  size: _vm.size_,
                  type: "number"
                },
                model: {
                  value: scope.row.fee,
                  callback: function ($$v) {
                    _vm.$set(scope.row, "fee", $$v);
                  },
                  expression: "scope.row.fee"
                }
              })];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            label: "操作",
            width: "60"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_c("el-button", {
                attrs: {
                  type: "danger",
                  size: _vm.size_,
                  icon: "el-icon-delete"
                },
                on: {
                  click: function ($event) {
                    return model.transFeeItems.splice(scope.$index, 1);
                  }
                }
              })];
            }
          }], null, true)
        })], 1), _c("el-button", {
          attrs: {
            type: "primary",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return model.transFeeItems.push({
                weight: undefined,
                fee: undefined
              });
            }
          }
        }, [_vm._v("添加")])];
      }
    }])
  }), _c("TransFeeCategoryAddDialog", {
    ref: "transFeeCategoryAddDialog",
    on: {
      done: function ($event) {
        _vm.getForm().refreshOptions("trans_fee_category_id");
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
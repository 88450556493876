import OrderPackageChangeUserAddDialog from './dialog/orderPackageChangeUserAddDialog';
import PrintRecipt from '@/components/Print/PrintRecipt';
export default {
  name: 'OrderPackageChangeUserIndex',
  components: {
    OrderPackageChangeUserAddDialog,
    PrintRecipt
  },
  data() {
    return {
      module: 'orderPackageChangeUser',
      operates: {
        del: false,
        look: false,
        add: false,
        edit: false,
        multiDel: false,
        other: [{
          title: '确认转仓',
          type: 'warning',
          show: row => row.status === 2,
          click: row => this.confirm(row)
        }, {
          title: '取消',
          type: 'danger',
          show: row => row.status === 0 || row.status === 2,
          click: row => this.toCancel(row)
        }]
        // recycle: { autoLink: true },
      },

      visible: false,
      list: [],
      model: {}
    };
  },
  methods: {
    getList() {
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$refs.orderPackageChangeUserAddDialog.open(row);
    },
    onAdd() {
      this.$refs.orderPackageChangeUserAddDialog.open({});
    },
    confirm(row) {
      this.$confirm('确认转仓吗？', '转仓提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`${this.module}/confirm`, {
          id: row.id
        }).then(res => {
          this.getList();
          this.list = res.data.list;
          this.visible = true;
        });
      }).catch(() => {});
    },
    toCancel(row) {
      this.$confirm('确认取消吗？', '取消提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('aaaaa');
        this.$w_fun.post(`${this.module}/cancel`, {
          id: row.id
        }).then(res => {
          this.getList();
        });
      }).catch(() => {});
    },
    async toPrint(row) {
      this.model = row;
      await this.$nextTick();
      await this.$nextTick();
      this.$refs.printRecipt.printReceipt(row.user_id, row);
    }
  }
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      title: "添加/编辑",
      form: _vm.form,
      action: _vm.formAction,
      width: _vm.$w_fun.isMobile() ? "100%" : "80%",
      itemWidth: "33%",
      "before-submit": _vm.beforeSubmit
    },
    on: {
      done: _vm.done
    },
    scopedSlots: _vm._u([{
      key: "goodsAdditions",
      fn: function ({
        model
      }) {
        return _vm._l(model.photos, function (goods) {
          return _c("w-form", {
            key: goods.id,
            ref: `additionForm${goods.id}`,
            refInFor: true,
            attrs: {
              "item-width": "48%",
              form: goods.form,
              labelWidth: "130px"
            }
          });
        });
      }
    }, {
      key: "orderNo",
      fn: function ({
        model
      }) {
        return [_c("el-link", {
          attrs: {
            href: "/#/order/order/get?id=" + model.order_id,
            target: "_blank"
          }
        }, [_vm._v(_vm._s(model.order_no))])];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "flex w100 border-f1f1f1",
    staticStyle: {
      height: "350px"
    }
  }, [_c("div", {
    staticClass: "w20 h100 p10"
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-width": "100px",
      "label-suffix": "："
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "快递单号"
    }
  }, [_c("el-input", {
    ref: "tracking_no",
    attrs: {
      size: _vm.size_,
      clearable: ""
    },
    model: {
      value: _vm.form.tracking_no_like,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tracking_no_like", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.tracking_no_like"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "地址码"
    }
  }, [_c("el-input", {
    attrs: {
      size: _vm.size_,
      clearable: ""
    },
    on: {
      blur: _vm.search
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.address_code_like,
      callback: function ($$v) {
        _vm.$set(_vm.form, "address_code_like", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.address_code_like"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "订单号"
    }
  }, [_c("el-input", {
    attrs: {
      size: _vm.size_,
      clearable: ""
    },
    on: {
      blur: _vm.search
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.order_no_like,
      callback: function ($$v) {
        _vm.$set(_vm.form, "order_no_like", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.order_no_like"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "平台订单号"
    }
  }, [_c("el-input", {
    attrs: {
      size: _vm.size_,
      clearable: ""
    },
    on: {
      blur: _vm.search
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.third_order_no_like,
      callback: function ($$v) {
        _vm.$set(_vm.form, "third_order_no_like", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.third_order_no_like"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "商品ID"
    }
  }, [_c("el-input", {
    attrs: {
      size: _vm.size_,
      clearable: ""
    },
    on: {
      blur: _vm.search
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.third_goods_id_match,
      callback: function ($$v) {
        _vm.$set(_vm.form, "third_goods_id_match", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.third_goods_id_match"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "会员码"
    }
  }, [_c("el-input", {
    attrs: {
      size: _vm.size_,
      clearable: ""
    },
    on: {
      blur: _vm.search
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.user_id_match,
      callback: function ($$v) {
        _vm.$set(_vm.form, "user_id_match", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.user_id_match"
    }
  })], 1)], 1)], 1), _c("div", {
    staticClass: "w80 h100 p10"
  }, [_c("el-table", {
    ref: "table",
    staticClass: "w100",
    attrs: {
      data: _vm.list,
      size: _vm.size_,
      "max-height": "330",
      border: "",
      "highlight-current-row": ""
    },
    on: {
      "current-change": _vm.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "order_no",
      label: "订单号",
      width: "140"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "third_order_no",
      label: "平台订单号",
      width: "140",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "tracking_no",
      label: "快递单号",
      width: "140",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      label: "商品ID",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return _vm._l(scope.row.orderGoods, function (goods) {
          return _c("p", {
            directives: [{
              name: "copy",
              rawName: "v-copy",
              value: goods.third_goods_id,
              expression: "goods.third_goods_id"
            }],
            key: goods.id,
            staticClass: "omit cursor",
            attrs: {
              title: goods.title
            }
          }, [_vm._v(" " + _vm._s(goods.third_goods_id) + " ")]);
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "商品图片",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return _vm._l(scope.row.orderGoods, function (goods) {
          return _c("el-tooltip", {
            key: goods.id,
            attrs: {
              content: goods.title
            }
          }, [_c("el-image", {
            staticClass: "w-image-40",
            attrs: {
              src: goods.thumb,
              "preview-src-list": scope.row.orderGoods.map(i => i.thumb),
              fit: "contain"
            }
          })], 1);
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "商品"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return _vm._l(scope.row.orderGoods, function (goods) {
          return _c("p", {
            key: goods.id,
            staticClass: "omit",
            attrs: {
              title: goods.title
            }
          }, [_vm._v(_vm._s(goods.title))]);
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "user_id",
      label: "会员码",
      width: "90"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "status_text",
      label: "状态",
      width: "90"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "orderInfo.address_code",
      label: "地址码",
      width: "90"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "验货拍照",
      prop: "addition_info",
      "show-overflow-tooltip": "",
      width: "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return _vm._l(scope.row.orderGoods, function (g) {
          return _c("p", {
            staticClass: "omit",
            attrs: {
              title: `${g.addition && g.addition.title}:${g.addition_comment}`
            }
          }, [_vm._v(_vm._s(g.addition && g.addition.title) + _vm._s(g.addition_comment ? `：${g.addition_comment}` : ""))]);
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "同捆",
      prop: "order_package_together_id_info",
      width: "70"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("span", {
          class: scope.row.order_package_together_id > 0 ? "red b" : ""
        }, [_vm._v(_vm._s(scope.row.order_package_together_id > 0 ? "是" : ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "100",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.status === 4 ? _c("el-button", {
          attrs: {
            type: "primary",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.edit(scope.row);
            }
          }
        }, [_vm._v("修改")]) : _vm._e()];
      }
    }])
  })], 1)], 1)]), _c("div", {
    staticClass: "w100 p10 mt20"
  }, [_c("w-form", {
    ref: "wForm",
    attrs: {
      title: "入库",
      form: _vm.model,
      "before-submit": _vm.beforeAddSubmit,
      action: "store/add",
      labelWidth: "100px"
    }
  }), _c("el-button", {
    staticClass: "ml40",
    attrs: {
      disabled: !_vm.canAdd,
      loading: _vm.loading,
      type: "primary",
      size: "medium"
    },
    on: {
      click: _vm.add
    }
  }, [_vm._v("入库")]), _c("el-button", {
    staticClass: "ml10",
    attrs: {
      disabled: !_vm.canAdd,
      type: "danger",
      size: "medium"
    },
    on: {
      click: _vm.printPackage
    }
  }, [_vm._v("打印入库标签")]), _c("el-button", {
    staticClass: "ml10",
    attrs: {
      disabled: !_vm.canAdd,
      type: "warning",
      size: "medium"
    },
    on: {
      click: _vm.addOther
    }
  }, [_vm._v("入库非订单包裹")])], 1), _c("PrintRecipt", {
    ref: "printRecipt"
  }), _c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      title: _vm.formTitle,
      form: _vm.packageForm,
      action: _vm.formAction,
      width: _vm.$w_fun.isMobile() ? "100%" : "60%",
      beforeSubmit: _vm.beforeSubmit
    },
    on: {
      done: _vm.formDone
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
import TranslateAddDialog from './dialog/translateAddDialog';
export default {
  name: 'TranslateIndex',
  components: {
    TranslateAddDialog
  },
  data() {
    return {
      module: 'translate',
      operates: {
        del: true,
        look: false,
        add: true,
        edit: true,
        multiDel: true,
        import: {
          url: 'translate/import',
          template: 'https://api.paijianggo.com/keywordReplace.xlsx'
        }
      }
    };
  },
  methods: {
    getList() {
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$refs.translateAddDialog.open(row);
    },
    onAdd() {
      this.$refs.translateAddDialog.open({});
    }
  }
};
export default {
  name: 'GoodsAddDialog',
  data() {
    return {
      module: 'goods',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      third_goods_id,
      site_id,
      third_seller_id,
      title,
      thumb,
      price,
      stock,
      status,
      is_succeess,
      third_updated_at,
      final_price,
      start_time,
      end_time,
      freight_status,
      auction_status,
      cate_id,
      renew_num,
      next_updated_at
    }) {
      this.form = {
        third_goods_id: {
          label: '三方ID',
          value: third_goods_id,
          attrs: {
            disabled: true
          }
        },
        site_id: {
          label: '所属平台',
          value: site_id,
          formType: 'select',
          options: '/siteConfig/select',
          attrs: {
            disabled: true
          }
        },
        third_seller_id: {
          label: '卖家ID',
          value: third_seller_id,
          attrs: {
            disabled: true
          }
        },
        start_time: {
          label: '上线时间',
          value: start_time,
          attrs: {
            disabled: true
          }
        },
        end_time: {
          label: '下线时间',
          value: end_time,
          attrs: {
            disabled: true
          }
        },
        freight_status: {
          label: '运费',
          value: freight_status,
          formType: 'select',
          options: [{
            label: '未知',
            value: 0
          }, {
            label: '包邮',
            value: 1
          }, {
            label: '货到付款',
            value: 2
          }, {
            label: '代扣',
            value: 3
          }, {
            label: '买家负担',
            value: 4
          }],
          attrs: {
            disabled: true
          }
        },
        auction_status: {
          label: '拍卖方式',
          value: auction_status,
          formType: 'select',
          options: [{
            label: '全部',
            value: 0
          }, {
            label: '竞价',
            value: 1
          }, {
            label: '一口价',
            value: 2
          }, {
            label: '人工下单',
            value: 3
          }],
          attrs: {
            disabled: true
          }
        },
        title: {
          label: '名称',
          value: title
        },
        thumb: {
          label: '图片',
          value: thumb,
          formType: 'upload'
        },
        price: {
          label: '单价',
          value: price,
          formType: 'number',
          attrs: {
            append: '円'
          }
        },
        stock: {
          label: '库存',
          value: stock,
          formType: 'number'
        },
        status: {
          label: '商品状态',
          value: status,
          formType: 'status'
        },
        final_price: {
          label: '立即购买价格',
          value: final_price,
          formType: 'number'
        }
        // cate_id: {
        //   label: '分类id',
        //   value: cate_id,
        //   formType: 'select',
        //   options: '/cate/select'
        // },
        // renew_num: {label: '更新次数', value: renew_num, formType: 'number'},
        // next_updated_at: {label: '', value: next_updated_at},
      };

      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};
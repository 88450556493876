var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-tabs-table", {
    ref: "wTable",
    attrs: {
      operates: _vm.operates,
      module: _vm.module,
      k: ""
    },
    on: {
      add: _vm.onAdd,
      edit: _vm.onEdit
    }
  }), _c("UserBargainDoneDialog", {
    ref: "userBargainDoneDialog",
    on: {
      done: _vm.getList
    }
  }), _c("UserBargainAddDialog", {
    ref: "userBargainAddDialog",
    on: {
      done: _vm.getList
    }
  }), _c("userSendContentAddDialog", {
    ref: "userSendContentAddDialog",
    on: {
      done: _vm.getList
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
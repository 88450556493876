var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-tabs-table", {
    ref: "wTable",
    attrs: {
      operates: _vm.operates,
      module: _vm.module,
      k: ""
    },
    on: {
      add: _vm.onAdd,
      edit: _vm.onEdit
    },
    scopedSlots: _vm._u([{
      key: "CateSlot",
      fn: function ({
        row
      }) {
        return [_c("el-link", {
          attrs: {
            href: row.third_category_id_url,
            target: "_blank"
          }
        }, [_vm._v(" " + _vm._s(row.third_category_id) + " ")])];
      }
    }])
  }), _c("GoodsCategoryAddDialog", {
    ref: "goodsCategoryAddDialog",
    on: {
      done: _vm.getList
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
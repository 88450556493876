export default {
  name: 'BannerAddDialog',
  data() {
    return {
      module: 'banner',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      site_id,
      thumb,
      jump,
      article_id,
      status,
      sort
    }) {
      this.form = {
        site_id: {
          label: '站点',
          value: site_id,
          formType: 'radio',
          options: 'siteConfig/select?index=1&ids=1,2,3,4,5,6,7',
          methods: {
            change: v => {
              const formItems = this.getForm().formItems;
              const index = formItems.findIndex(item => item.prop === 'thumb');
              if (v === 0) {
                formItems[index].attrs.tip = '建议尺寸：420px * 180px';
              } else {
                formItems[index].attrs.tip = '建议尺寸：420px * 150px';
              }
            }
          },
          attrs: {
            isButton: true
          }
        },
        thumb: {
          label: '图片',
          value: [{
            path: thumb,
            url: thumb
          }],
          formType: 'upload',
          attrs: {
            drag: true,
            accept: '.jpg,.png,.jpeg',
            tip: site_id > 0 ? '建议尺寸：420px * 150px' : '建议尺寸：420px * 180px'
          },
          type: 'array'
        },
        jump: {
          label: '跳转地址',
          value: jump,
          required: false
        },
        article_id: {
          label: '文章',
          value: article_id,
          formType: 'select',
          options: '/article/select',
          required: false
        },
        status: {
          label: '状态',
          value: status,
          formType: 'status'
        },
        sort: {
          label: '排序',
          value: sort,
          formType: 'number',
          required: false
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};
export default {
  name: 'StoreAddressAddDialog',
  data() {
    return {
      module: 'storeAddress',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      title,
      family_name,
      first_name,
      family_name_kana,
      first_name_kana,
      prefecture,
      city,
      detail,
      detail2,
      zip,
      phone,
      status
    }) {
      this.form = {
        title: {
          label: '地址名称',
          value: title
        },
        family_name: {
          label: '姓',
          value: family_name
        },
        first_name: {
          label: '名',
          value: first_name
        },
        family_name_kana: {
          label: '姓氏假名',
          value: family_name_kana
        },
        first_name_kana: {
          label: '名字假名',
          value: first_name_kana
        },
        zip: {
          label: '邮编',
          value: zip
        },
        phone: {
          label: '电话',
          value: phone
        },
        prefecture: {
          label: '收货地址省',
          value: prefecture
        },
        city: {
          label: '收货地址市',
          value: city
        },
        detail: {
          label: '详细地址',
          value: detail
        },
        detail2: {
          label: '详细地址2',
          value: detail2
        },
        status: {
          label: '状态',
          value: status,
          formType: 'status'
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};
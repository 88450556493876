import { debounce } from 'lodash';
import PrintRecipt from '@/components/Print/PrintRecipt';
import dayjs from 'dayjs';
export default {
  name: 'StoreIndex',
  components: {
    PrintRecipt
  },
  computed: {
    size_() {
      return this.$store.getters['size'];
    },
    canAdd() {
      return true;
      const form = this.getForm();
      return form ? form.model.weight && form.model.package_no : false;
    }
  },
  mounted() {
    // window.addEventListener('keydown', this.handleKeyDown)
    this.$refs.tracking_no.focus();
  },
  beforeDestroy() {
    // window.removeEventListener('keydown', this.handleKeyDown)
  },
  watch: {
    'form.tracking_no_like': debounce(function (v) {
      this.search();
    }, 500)
  },
  data() {
    return {
      module: 'store',
      model: {
        id: {
          show: false,
          value: undefined
        },
        package_no: {
          label: '包裹号',
          value: undefined,
          attrs: {
            disabled: true
          }
        },
        size_type: {
          label: '包裹大小',
          value: undefined,
          formType: 'radio',
          options: '/orderPackage/sizeType',
          attrs: {
            isButton: true
          }
        },
        weight: {
          label: '重量',
          value: undefined,
          formType: 'number',
          attrs: {
            append: 'kg'
          }
        },
        order_package_tags: {
          label: '标签',
          value: [],
          formType: 'checkbox',
          options: '/store/packageTag',
          attrs: {
            isButton: true
          },
          required: false
        },
        remark: {
          label: '备注',
          value: '',
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          },
          required: false
        }
      },
      form: {
        address_code_like: undefined,
        tracking_no_like: undefined,
        user_id_match: undefined,
        order_no_like: undefined,
        third_order_no_like: undefined,
        third_goods_id_match: undefined
      },
      list: [],
      loading: false,
      lastInputTime: undefined,
      inputStr: '',
      focus: '',
      user_id: undefined,
      packageForm: {},
      formAction: 'orderPackage/edit',
      formTitle: '修改包裹'
    };
  },
  methods: {
    dayjs,
    search() {
      if (!this.form.address_code_like && !this.form.tracking_no_like && !this.form.user_id_match && !this.form.order_no_like && !this.form.third_order_no_like && !this.form.third_goods_id_match) {
        this.list = [];
        return;
      }
      this.clearForm();
      this.$w_fun.post(`${this.module}/index`, this.form).then(res => {
        this.list = res.data.list;
        if (this.list.length === 1) {
          this.$refs.table.setCurrentRow(this.list[0]);
          this.handleSelectionChange(this.list[0]);
        }
      });
    },
    handleKeyDown(v) {
      let timer;
      if (this.lastInputTime && Date.now() - this.lastInputTime < 300) {
        this.inputStr += v.key;
        timer && clearTimeout(timer);
      } else {
        this.inputStr = v.key;
        timer && clearTimeout(timer);
      }
      this.lastInputTime = Date.now();
      timer = setTimeout(() => {
        if (this.inputStr.startsWith('xxxx')) {
          this.$refs.tracking_no.focus();
          this.form.tracking_no_like = this.inputStr.substring(4);
          this.search();
        }
        if (this.inputStr.startsWith('yyyy')) {
          const n = Number(this.inputStr.substring(4));
          if (!isNaN(n)) {
            this.getForm().model.weight = n;
          }
        }
      }, 1200);
    },
    clearForm() {
      this.user_id = 0;
      const model = this.getForm().model;
      model.id = undefined;
      model.package_no = '';
      model.weight = undefined;
      model.order_package_tags = [];
      model.remark = '';
      // model.photos = []
    },

    handleSelectionChange(v) {
      if (!v || v.length === 0) {
        if (this.list.length > 0) {
          this.clearForm();
        }
        return;
      }
      this.user_id = v.user_id;
      const u = v.user_id + '';
      this.getForm().model.id = v.id;
      this.getForm().model.package_no = this.fillZero(v.id + '', 10);
      this.getForm().model.remark = v.remark;
      this.getForm().$el[3].focus();
    },
    async add() {
      try {
        this.loading = true;
        await this.getForm().submit();
        await this.printPackage();
        this.loading = false;
        setTimeout(() => {
          this.form = {
            address_code_like: undefined,
            tracking_no_like: undefined,
            user_id_match: undefined,
            order_no_like: undefined,
            third_order_no_like: undefined,
            third_goods_id_match: undefined
          };
          this.$refs.tracking_no.focus();
        }, 1000);
      } catch (e) {
        this.loading = false;
      }
    },
    edit(row) {
      this.formTitle = '修改包裹';
      this.packageForm = {
        id: {
          show: false,
          value: row.id
        },
        status: {
          label: '状态',
          value: row.status,
          formType: 'radio',
          options: 'store/packageStatus',
          attrs: {
            isButton: true
          }
        },
        package_no: {
          label: '包裹号',
          value: row.package_no,
          attrs: {
            disabled: true
          }
        },
        size_type: {
          value: row.size_type,
          label: '包裹大小',
          formType: 'radio',
          options: '/orderPackage/sizeType',
          required: false,
          attrs: {
            isButton: true
          }
        },
        weight: {
          label: '重量',
          value: row.weight,
          required: false,
          formType: 'number',
          attrs: {
            append: 'kg',
            clearable: true
          }
        }
      };
      this.formAction = 'orderPackage/edit';
      this.$refs.wDialogForm.visible = true;
    },
    async printPackage() {
      if (!this.getForm().model.weight || !this.getForm().model.package_no) {
        this.$message.error('无效的包裹');
        return;
      }
      this.$refs.printRecipt.printReceipt(this.user_id, this.getForm().model);
    },
    async addOther() {
      const {
        data
      } = await this.$w_fun.post('/orderPackage/newEmptyPackage');
      const {
        data: {
          list
        }
      } = await this.$w_fun.post('/siteConfig/select');
      list.sort((a, b) => {
        return a.value > 7 || b.value > 7 ? b.value - a.value : a.value - b.value;
      });
      this.formTitle = '入库包裹';
      this.packageForm = {
        id: {
          show: false,
          value: data.id
        },
        site_id: {
          label: '站点',
          value: undefined,
          formType: 'select',
          options: list,
          methods: {
            change: v => {
              if (v && v === 8 && !this.$refs.wDialogForm.$refs.form.model.trans_package_fee) {
                this.$w_fun.post(`${this.module}/transPackageAmount`).then(res => {
                  this.$refs.wDialogForm.$refs.form.model.trans_package_fee = res.data.amount;
                });
              }
            }
          }
        },
        user_id: {
          label: '会员',
          value: undefined,
          formType: 'remote',
          options: '/user/select',
          methods: {
            change: v => {
              const model = this.$refs.wDialogForm.$refs.form.model;
              const id = v + '';
              model.package_no = this.fillZero(data.id + '', 10);
            }
          }
        },
        package_no: {
          label: '包裹号',
          value: undefined,
          attrs: {
            disabled: true
          }
        },
        size_type: {
          value: undefined,
          label: '包裹大小',
          formType: 'radio',
          options: '/orderPackage/sizeType',
          attrs: {
            isButton: true
          }
        },
        weight: {
          label: '重量',
          value: undefined,
          formType: 'number',
          attrs: {
            append: 'kg',
            clearable: true
          }
        },
        title: {
          label: '包裹名称',
          value: '',
          required: false
        },
        price: {
          label: '商品金额',
          value: 0,
          required: false,
          tooltip: '国际发货时勾选包裹统计商品总金额用'
        },
        trans_package_fee: {
          show: m => m.site_id === 8,
          label: '转运费',
          value: 0,
          formType: 'amount'
        },
        thumb: {
          label: '图片',
          value: [],
          formType: 'upload',
          attrs: {
            accept: '.jpg,.png,.jpeg'
          },
          type: 'array',
          required: false
        },
        remark: {
          label: '备注',
          value: '',
          required: false,
          max: 500,
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          }
        }
      };
      this.formAction = 'store/addOther';
      this.$refs.wDialogForm.visible = true;
    },
    async beforeSubmit(data) {
      if (this.formAction === 'store/addOther') {
        const model = this.getForm().model;
        model.package_no = data.package_no;
        model.size_type = data.size_type;
        model.weight = data.weight;
        this.user_id = data.user_id;
        // if (data.photos && data.photos.length > 0) {
        //   data.photos = { thumbs: data.photos }
        //   data.is_addition = 0
        // }
      }

      return data;
    },
    async beforeAddSubmit(data) {
      // data.photos = { thumbs: data.photos || [] }
      data.is_addition = 0;
      return data;
    },
    formDone() {
      if (this.formAction === 'store/addOther') {
        this.printPackage();
      } else {
        this.search();
      }
    },
    getForm() {
      return this.$refs.wForm;
    },
    fillZero(v, len) {
      return '0'.repeat(len - v.length) + v;
    }
  }
};
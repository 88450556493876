export default {
  name: 'SellerBlacklistAddDialog',
  data() {
    return {
      module: 'sellerBlacklist',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      site_id = 4,
      third_seller_id,
      remark
    }) {
      this.form = {
        site_id: {
          label: '站点',
          value: site_id,
          formType: 'select',
          options: '/siteConfig/select?ids=3,4,5,6,7'
        },
        third_seller_id: {
          label: '三方id',
          value: third_seller_id
        },
        remark: {
          label: '备注',
          value: remark,
          required: false
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    },
    done(v) {
      this.$emit('done', v);
      this.$store.dispatch('cleanColumnOptions', this.module + '/select');
    }
  }
};
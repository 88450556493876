export default {
  name: 'userSendContentAddDialog',
  components: {},
  data() {
    return {
      module: 'userBargain',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      status,
      site_id,
      site_account_id,
      content,
      real_content
    }) {
      this.form = {
        site_account_id: {
          label: '留言账号',
          value: site_account_id,
          formType: 'select',
          options: '/siteAccount/select?site_id_match=' + site_id
        },
        content: {
          label: '申请留言内容',
          value: content,
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            },
            disabled: true
          }
        },
        real_content: {
          label: '实际留言内容',
          value: real_content,
          required: false,
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          }
        }
      };
      this.form.status = {
        show: false,
        value: status
      };
      this.form.id = {
        show: false,
        value: id
      };
      this.formAction = `${this.module}/send_message`;
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};
import OrderPayAddDialog from './dialog/orderPayAddDialog';
import { isPro } from '@/utils/w';
export default {
  name: 'OrderPayIndex',
  components: {
    OrderPayAddDialog
  },
  data() {
    return {
      module: 'orderPay',
      operates: {
        del: false,
        look: false,
        add: false,
        edit: false,
        multiDel: false,
        // recycle: { autoLink: true },
        other: [{
          title: '模拟支付',
          type: 'warning',
          show: row => row.status === 0 || row.status === 2,
          click: row => this.paySuccess(row)
        }]
      },
      form: {}
    };
  },
  methods: {
    getList() {
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$refs.orderPayAddDialog.open(row);
    },
    onAdd() {
      this.$refs.orderPayAddDialog.open({});
    },
    paySuccess(row) {
      this.form = {
        id: {
          show: false,
          value: row.id
        },
        pay_no: {
          label: '支付单编号',
          value: row.pay_no,
          readonly: true
        },
        user_coupon_id: {
          label: '优惠券',
          value: undefined,
          formType: 'select',
          options: '/orderPay/availableCoupons?id=' + row.id,
          required: false
        }
      };
      this.$refs.wDialogForm.visible = true;
    }
  }
};
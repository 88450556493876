import "core-js/modules/es.array.push.js";
import WorkAddDialog from '../work/dialog/workAddDialog';
import OrderTogetherDialog from './dialog/orderTogetherDialog';
export default {
  name: 'OrderIndex',
  components: {
    WorkAddDialog,
    OrderTogetherDialog
  },
  props: {
    query: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      module: 'order',
      operates: {
        del: false,
        look: false,
        add: true,
        edit: true,
        multiDel: false,
        other: [{
          title: '工单',
          type: 'warning',
          show: row => (!row.works || row.works.length === 0) && row.status > 0,
          click: row => this.addWork(row)
        }, {
          title: '转机器下单',
          type: 'warning',
          show: row => row.is_manual_purchase && [1, 9].indexOf(row.status) > -1,
          click: row => this.robot(row)
        }]
      },
      currentTab: '',
      canTogether: false
    };
  },
  methods: {
    changeTab(v) {
      this.currentTab = v;
    },
    getList() {
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$router.push({
        name: 'OrderGet',
        query: {
          id: row.id
        }
      });
    },
    onAdd() {
      this.$router.push({
        name: 'OrderAdd',
        query: this.query
      });
    },
    addWork(row) {
      this.$refs.workAddDialog.open({
        obj_id: row.id,
        obj_title: '订单：' + row.order_no,
        obj_type_id: 0
      });
    },
    selectChange() {
      const selection = this.$refs.wTable.$refs.table.selection;
      if (selection.length < 2) {
        this.canTogether = false;
        return;
      }
      if (selection.filter(i => [1, 2, 3].indexOf(i.status) > -1).length === 0) {
        this.canTogether = false;
        return;
      }
      if (selection.filter(i => [1, 2].indexOf(i.site_id) > -1).length > 0) {
        this.canTogether = false;
        return;
      }
      if (Array.from(new Set(selection.map(i => i.orderGoods[0].third_seller_id))).length > 1) {
        this.canTogether = false;
        return;
      }
      if (Array.from(new Set(selection.map(i => i.site_id))).length > 1) {
        this.canTogether = false;
        return;
      }
      const orderPackageTogetherIds = [];
      for (const item of selection) {
        if (!item.orderPackages) {
          continue;
        }
        for (const p of item.orderPackages) {
          if (p.order_package_together_id > 0) {
            orderPackageTogetherIds.push(p.order_package_together_id);
          }
        }
      }
      if (Array.from(new Set(orderPackageTogetherIds)).length > 1) {
        this.canTogether = false;
        return;
      }
      this.canTogether = true;
    },
    addTogether() {
      this.$refs.orderTogetherDialog.open(this.$refs.wTable.$refs.table.selection);
    },
    robot(row) {
      this.$confirm('确认转机器下单吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`order/robot`, {
          id: row.id
        }).then(res => {
          this.getList();
        });
      }).catch(() => {});
    }
  }
};
export default {
  name: 'BindOldSystemDialog',
  data() {
    return {
      module: 'user',
      formAction: 'user/bindOldSystem',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      oldUser = {}
    }) {
      this.form = {
        id: {
          label: '专属码',
          value: id,
          readonly: true
        },
        user_id: {
          value: id,
          show: false
        },
        ubid: {
          label: '旧专属码',
          value: oldUser && oldUser.ubid
        },
        email: {
          label: '旧邮箱',
          value: oldUser && oldUser.email
        }
      };
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};
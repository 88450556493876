var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "w100"
  }, [_c("w-table", {
    ref: "wTable",
    attrs: {
      operates: _vm.operates,
      module: _vm.module,
      actions: _vm.actions,
      query: _vm.query,
      pagination: false,
      asyncSummary: false,
      "max-height": "100%",
      getList: v => _vm.$emit("getList", v)
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
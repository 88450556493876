import AdminSettingAddDialog from '@/views/adminSetting/dialog/adminSettingAddDialog';
export default {
  name: 'UserBargainAddDialog',
  components: {
    AdminSettingAddDialog
  },
  data() {
    return {
      module: 'userBargain',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      user_id,
      site_id,
      third_goods_id,
      thumb,
      title,
      third_seller_id,
      content,
      reason,
      remark
    }) {
      this.form = {
        user_id: {
          label: '会员',
          value: user_id,
          formType: 'remote',
          options: '/user/select',
          readonly: !!id
        },
        site_id: {
          label: '站点',
          value: site_id,
          formType: 'select',
          options: '/siteConfig/select?ids=3,4,5,6,7',
          readonly: !!id
        },
        third_goods_id: {
          label: '商品ID',
          value: third_goods_id,
          readonly: !!id
        },
        thumb: {
          label: '图片',
          value: thumb,
          formType: 'upload',
          readonly: !!id,
          required: false
        },
        title: {
          label: '商品名称',
          value: title,
          readonly: !!id,
          required: false
        },
        third_seller_id: {
          label: '卖家ID',
          value: third_seller_id,
          readonly: !!id,
          required: false
        },
        amount: {
          label: '费用',
          value: undefined,
          formType: 'amount',
          show: !id,
          required: false
        },
        status: {
          show: !!id,
          label: '审核状态',
          value: 4,
          formType: 'select',
          options: {
            url: '/userBargain/audit_status',
            key: 'statusMap'
          }
        },
        content: {
          label: '留言内容',
          value: content,
          required: false,
          width: '100%'
        },
        reason: {
          label: '审核失败原因',
          value: reason,
          show: m => m.status === 5,
          formType: 'select',
          options: 'adminSetting/valueSelect?module_match=userBargainReason',
          attrs: {
            quickAdd: () => {
              this.$refs.adminSettingAddDialog.open({
                module: 'userBargainReason'
              });
            }
          },
          width: '100%'
        },
        remark: {
          label: '备注',
          value: remark,
          formType: 'textarea',
          required: false,
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          },
          max: 500,
          width: '100%'
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};
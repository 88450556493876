import OrderRefundAddDialog from '@/views/orderRefund/dialog/orderRefundAddDialog';
export default {
  name: 'OrderPayTable',
  components: {
    OrderRefundAddDialog
  },
  props: {
    query: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      module: 'orderPay',
      operates: {
        del: false,
        look: false,
        add: false,
        edit: false,
        multiDel: false,
        other: [{
          title: '取消',
          click: row => this.toCancelPay(row),
          show: row => row.status === 0
        }, {
          title: '退款',
          type: 'warning',
          click: row => this.toAddRefund(row),
          show: row => row.status === 1 || row.status === 4 || row.status === 6
        }]
        // recycle: { autoLink: true },
      },

      title: '修改',
      actions: {
        columns: [{
          label: '创建时间',
          v: 'created_at'
        }, {
          label: '支付时间',
          v: 'paid_at'
        }, {
          label: '支付金额',
          v: 'amount'
        }, {
          label: '退款金额',
          v: 'refund_amount'
        }, {
          label: '汇率',
          v: 'exchange_rate'
        }, {
          label: '折扣',
          v: 'discount'
        }, {
          label: '状态',
          v: 'status_text',
          colors: {
            '已支付': 'green',
            '全额退款': 'red',
            '部分退款': 'orange'
          }
        }, {
          label: '支付方式',
          v: 'payment_type_text'
        }, {
          label: '支付类型',
          v: 'pay_aim_type_text'
        }, {
          label: '支付内容',
          v: 'subject'
        }, {
          label: '流水号',
          v: 'trade_no'
        }]
      },
      form: {},
      formAction: ''
    };
  },
  methods: {
    getList() {
      this.$refs.wTable.onRefresh();
    },
    formDone() {
      this.getList();
      this.$emit('done');
    },
    addPay(addField, exchange_rate) {
      this.form = {
        ...addField,
        amount: {
          label: '支付金额',
          value: undefined,
          formType: 'amount',
          attrs: {
            exchange_rate: exchange_rate
          }
        },
        subject: {
          label: '支付内容',
          value: '',
          max: 10
        }
      };
      this.formAction = 'orderPay/add';
      this.$refs.wDialogForm.visible = true;
    },
    async toCancelPay(row) {
      const confirm = await this.$confirm('确定取消吗？', '提示', {
        type: 'warning'
      }).catch(() => {});
      if (!confirm) {
        return;
      }
      this.$w_fun.post(`orderPay/cancel`, {
        id: row.id
      }).then(res => {
        this.getList();
      });
    },
    toAddRefund(row) {
      this.$refs.orderRefundAddDialog.open({
        ...row,
        ...this.query
      });
    }
  }
};
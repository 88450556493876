export default {
  name: 'UserMsgTemplateAddDialog',
  data() {
    return {
      module: 'userMsgTemplate',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      user_msg_type,
      status,
      content,
      msg_template
    }) {
      this.form = {
        user_msg_type: {
          label: '类型',
          value: user_msg_type,
          formType: 'select',
          options: {
            url: '/userMsgTemplate/create',
            key: 'userMsgType'
          }
        },
        status: {
          label: '状态',
          value: status,
          formType: 'status'
        },
        msg_template: {
          label: '短信模板',
          value: msg_template,
          formType: 'select',
          options: {
            url: '/userMsgTemplate/create',
            key: 'msgTemplate'
          },
          tooltip: '阿里云申请模板ID，留空表示不起用短信通知',
          required: false
        },
        content: {
          label: '站内信内容',
          value: content,
          tooltip: '可用参数{order_no}代表订单号，{title}代表商品名称，eg:"您的订单{order_no}有变更，需要支付"',
          formType: 'textarea',
          max: 500,
          required: false
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    },
    done(v) {
      this.$emit('done', v);
      this.$store.dispatch('cleanColumnOptions', this.module + '/select');
    }
  }
};
import "core-js/modules/es.array.push.js";
import OrderPackageAddDialog from './dialog/orderPackageAddDialog';
import PrintRecipt from '@/components/Print/PrintRecipt.vue';
export default {
  name: 'OrderPackageIndex',
  components: {
    PrintRecipt,
    OrderPackageAddDialog
  },
  data() {
    return {
      module: 'orderPackage',
      operates: {
        del: true,
        look: false,
        add: false,
        edit: true,
        multiDel: false,
        other: [
        // { title: '历史', click: (row, $index) => this.onLook(row), type: 'default' },
        {
          title: '订单',
          show: m => m.order_id > 0,
          click: (row, $index) => this.toOrder(row),
          type: 'default'
        }, {
          title: '打印',
          show: m => m.status === 4,
          click: (row, $index) => this.printBarCode(row),
          type: 'default'
        }, {
          title: '拦截',
          type: 'warning',
          show: row => [4, 5, 6].indexOf(row.status) > -1 && row.site_id === 3 && row.order_id > 0 && row.orderInfo && !row.orderInfo.is_third_confirm && row.orderInfo.is_auto_confirm,
          click: row => this.intercept(row)
        }, {
          title: '确认收货',
          type: 'warning',
          show: row => [4, 5, 6].indexOf(row.status) > -1 && row.site_id === 3 && row.order_id > 0 && row.orderInfo && !row.orderInfo.is_third_confirm,
          click: row => this.confirmThirdOrder(row)
        }]
        // recycle: { autoLink: true },
      },

      dialogVisible: false,
      histories: [],
      user_id: 0
    };
  },
  methods: {
    getList() {
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$w_fun.post(`${this.module}/get`, {
        id: row.id
      }).then(res => {
        this.$refs.orderPackageAddDialog.open({
          ...res.data.detail,
          allGoods: res.data.allGoods || []
        }, [], !!row.to_user_id);
      });
    },
    onAdd() {},
    onLook(row) {
      this.histories = [];
      this.dialogVisible = true;
      this.$w_fun.post('orderPackage/histories', {
        id: row.id
      }).then(res => {
        this.histories = res.data.list;
      }).catch(() => {});
    },
    toOrder(row) {
      this.$router.push({
        name: 'OrderGet',
        query: {
          id: row.order_id
        }
      });
    },
    confirmThirdOrder(row) {
      this.$confirm('确认操作吗？', '确认收货提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`order/confirmThirdOrder`, {
          id: row.order_id
        }).then(res => {
          this.getList();
        });
      }).catch(() => {});
    },
    intercept(row) {
      this.$confirm('确认操作吗？', '拦截自动收货提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`order/intercept`, {
          id: row.order_id
        }).then(res => {
          this.getList();
        });
      }).catch(() => {});
    },
    async printBarCode(row) {
      this.user_id = row.user_id;
      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();
      this.$refs.printRecipt.printReceipt(row.user_id, row);
    }
  }
};
export default {
  name: 'OrderPackageChangeUserAddDialog',
  data() {
    return {
      module: 'orderPackageChangeUser',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      user_id,
      order_pay_id,
      status,
      amount,
      pay_status
    }) {
      this.form = {
        user_id: {
          label: '',
          value: user_id,
          formType: 'select',
          options: '/user/select'
        },
        order_pay_id: {
          label: '支付单',
          value: order_pay_id,
          formType: 'select',
          options: '/orderPay/select'
        },
        status: {
          label: '状态',
          value: status,
          formType: 'status'
        },
        amount: {
          label: '金额',
          value: amount,
          formType: 'number'
        },
        pay_status: {
          label: '支付状态',
          value: pay_status,
          formType: 'number'
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    },
    done(v) {
      this.$emit('done', v);
      this.$store.dispatch('cleanColumnOptions', this.module + '/select');
    }
  }
};
export default {
  name: 'UserBargainDoneDialog',
  data() {
    return {
      module: 'userBargain',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      is_done,
      reason,
      remark
    }) {
      this.form = {
        is_done: {
          label: '是否完成',
          value: is_done,
          formType: 'boolean'
        },
        remark: {
          label: '备注',
          value: remark,
          formType: 'textarea',
          required: false,
          max: 500
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/message_success`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};
import UserBargainDoneDialog from './dialog/userBargainDoneDialog.vue';
import UserBargainAddDialog from './dialog/userBargainAddDialog.vue';
import userSendContentAddDialog from './dialog/userSendContentAddDialog';
export default {
  name: 'UserBargainIndex',
  components: {
    UserBargainAddDialog,
    userSendContentAddDialog,
    UserBargainDoneDialog
  },
  data() {
    return {
      module: 'userBargain',
      operates: {
        del: false,
        look: false,
        add: true,
        edit: true,
        multiDel: false,
        // recycle: { autoLink: true },
        other: [{
          title: '完成',
          type: 'default',
          click: row => this.onDone(row),
          show: row => row.is_done === 0
        }, {
          title: '审核',
          type: 'warning',
          show: row => row.status === 3,
          click: row => this.onEdit(row)
        }, {
          title: '留言',
          type: 'primary',
          show: row => row.status === 4 && row.site_id === 3,
          click: row => this.onSend(row)
        }]
      }
    };
  },
  methods: {
    getList() {
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$refs.userBargainAddDialog.open(row, row.status === 1 || row.status === 2);
    },
    onAdd() {
      this.$refs.userBargainAddDialog.open({});
    },
    onSend(row) {
      this.$refs.userSendContentAddDialog.open(row);
    },
    onDone(row) {
      this.$refs.userBargainDoneDialog.open(row);
    }
  }
};
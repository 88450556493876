var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-tabs-table", {
    ref: "wTable",
    attrs: {
      operates: _vm.operates,
      module: _vm.module,
      k: ""
    },
    on: {
      add: _vm.onAdd,
      edit: _vm.onEdit
    },
    scopedSlots: _vm._u([{
      key: "objInfo",
      fn: function ({
        row
      }) {
        return [_vm._l(row.orders, function (o) {
          return _c("div", [_c("el-link", {
            attrs: {
              href: o.link,
              target: "_blank"
            }
          }, [_vm._v(_vm._s(o.order_no))])], 1);
        }), _vm._l(row.orderCombines, function (o) {
          return _c("div", [_c("el-link", {
            attrs: {
              href: o.link,
              target: "_blank"
            }
          }, [_vm._v(_vm._s(o.order_no))])], 1);
        }), _vm._l(row.orderPackages, function (o) {
          return _c("div", [_c("el-link", {
            attrs: {
              href: o.link,
              target: "_blank"
            }
          }, [_vm._v(_vm._s(o.package_no))])], 1);
        })];
      }
    }])
  }), _c("OrderPayAddDialog", {
    ref: "orderPayAddDialog",
    on: {
      done: _vm.getList
    }
  }), _c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      title: "模拟支付",
      form: _vm.form,
      action: "orderPay/paySuccess",
      width: _vm.$w_fun.isMobile() ? "100%" : "80%"
    },
    on: {
      done: _vm.getList
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
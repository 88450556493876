export default {
  name: 'GoodsCategoryAddDialog',
  data() {
    return {
      module: 'goodsCategory',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      title,
      is_direct_get,
      is_promotion,
      machine
    }) {
      this.form = {
        title: {
          label: '分类名称',
          value: title
        },
        is_direct_get: {
          label: '是否抓取',
          value: is_direct_get,
          formType: 'boolean'
        },
        is_promotion: {
          label: '是否提级',
          value: is_promotion,
          formType: 'boolean'
        },
        machine: {
          label: '抓取机器',
          value: machine,
          formType: 'select',
          options: '/goods_category/machine'
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    },
    done(v) {
      this.$emit('done', v);
      this.$store.dispatch('cleanColumnOptions', this.module + '/select');
    }
  }
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      display: "none"
    }
  }, [_c("iframe", {
    ref: "printRecipt",
    attrs: {
      id: "printInform",
      src: "https://www.cbecology.com/print_45bf5a9ef.html"
    }
  }), _vm._t("printRecipt")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
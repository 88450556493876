var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-tabs-table", {
    ref: "wTable",
    attrs: {
      operates: _vm.operates,
      module: _vm.module,
      k: ""
    },
    on: {
      add: _vm.onAdd,
      edit: _vm.onEdit
    },
    scopedSlots: _vm._u([{
      key: "moreOperate",
      fn: function (data) {
        return [data.row.status === 1 ? _c("el-button", {
          staticClass: "filter-item",
          attrs: {
            type: "success"
          },
          on: {
            click: function ($event) {
              return _vm.onCoupon(data.row);
            }
          }
        }, [_vm._v("赠券")]) : _vm._e()];
      }
    }])
  }), _c("UserAddDialog", {
    ref: "userAddDialog",
    on: {
      done: _vm.getList
    }
  }), _c("UserCouponDialog", {
    ref: "userCouponDialog"
  }), _c("BindOldSystem", {
    ref: "bindOldSystem",
    on: {
      done: _vm.getList
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
export default {
  name: 'CouponTable',
  props: {
    query: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      module: 'userCoupon',
      operates: {
        del: false,
        look: false,
        add: false,
        edit: false,
        multiDel: false
      },
      actions: {
        index: 'userCoupon/coupons',
        columns: [{
          label: '名称',
          v: 'title'
        }, {
          label: '类型',
          v: 'coupon_type_text'
        }, {
          label: '使用类型',
          v: 'use_type_text'
        }, {
          label: '优惠金额',
          v: 'discount'
        }, {
          label: '使用时间',
          v: 'used_at'
        }]
      },
      form: {},
      formAction: ''
    };
  },
  methods: {
    getList() {
      this.$refs.wTable.onRefresh();
    }
  }
};
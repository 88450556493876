export default {
  name: 'UserAddressAddDialog',
  data() {
    return {
      module: 'userAddress',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      user_id,
      province_id,
      city_id,
      region_id,
      detail,
      is_default,
      consignee,
      phone,
      id_card_no,
      id_card_front,
      id_card_back
    }) {
      const _this = this;
      this.form = {
        user_id: {
          label: '会员',
          value: user_id,
          formType: 'remote',
          options: '/user/select'
        },
        location: {
          label: '地区',
          value: [province_id, city_id, region_id],
          formType: 'cascader',
          options: {
            url: '/address/select',
            nodeKey: 'pid_match'
          },
          lazy: true
        },
        detail: {
          label: '详细地址',
          value: detail
        },
        is_default: {
          label: '默认地址',
          value: is_default,
          formType: 'boolean'
        },
        consignee: {
          label: '收件人',
          value: consignee
        },
        phone: {
          label: '电话',
          value: phone,
          pattern: 'phone'
        },
        id_card_no: {
          label: '身份证',
          value: id_card_no,
          pattern: 'idCard'
        },
        id_card_front: {
          label: '身份证正面',
          value: id_card_front,
          formType: 'upload'
        },
        id_card_back: {
          label: '身份证反面',
          value: id_card_back,
          formType: 'upload'
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-tabs-table", {
    ref: "wTable",
    attrs: {
      operates: _vm.operates,
      module: _vm.module,
      actions: {
        tabs: _vm.module + "/tabs"
      },
      "tab-key": "order_package__status_match",
      k: ""
    },
    on: {
      add: _vm.onAdd,
      edit: _vm.onEdit
    },
    scopedSlots: _vm._u([{
      key: "order_package_tag_id",
      fn: function ({
        row
      }) {
        return _vm._l(row.orderPackageTags, function (tag) {
          return _c("el-tag", {
            key: tag.id,
            attrs: {
              type: "success"
            }
          }, [_vm._v(_vm._s(tag.title))]);
        });
      }
    }])
  }), _c("OrderPackageAddDialog", {
    ref: "orderPackageAddDialog",
    on: {
      done: _vm.getList
    }
  }), _c("el-dialog", {
    attrs: {
      title: "提示",
      visible: _vm.dialogVisible,
      width: "60%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-table", {
    staticClass: "w100",
    attrs: {
      data: _vm.histories,
      size: _vm.$store.getters["size"],
      border: "",
      stripe: "",
      "max-height": "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "created_at",
      label: "日期",
      width: "140"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "admin_id",
      label: "操作人",
      width: "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.admin_id === -1 ? "用户" : scope.row.admin_id === 0 ? "系统" : scope.row.admin.nickname) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "comment",
      label: "内容"
    }
  })], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function ($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("确 定")])], 1)], 1), _c("PrintRecipt", {
    ref: "printRecipt"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
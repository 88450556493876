var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-tabs-table", {
    ref: "wTable",
    attrs: {
      operates: _vm.operates,
      module: _vm.module,
      k: ""
    },
    on: {
      add: _vm.onAdd,
      edit: _vm.onEdit
    },
    scopedSlots: _vm._u([{
      key: "orderPackages",
      fn: function ({
        row
      }) {
        return [_c("el-table", {
          key: row.id,
          attrs: {
            data: row.orderPackages,
            size: _vm.$store.getters["size"],
            stripe: ""
          }
        }, [_c("el-table-column", {
          attrs: {
            prop: "orderGoods",
            label: "商品",
            width: "180",
            "show-overflow-tooltip": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return _vm._l(scope.row.orderGoods, function (item) {
                return _c("div", {
                  staticClass: "w100 flex border-f1f1f1-bottom"
                }, [_c("el-image", {
                  staticClass: "w-image-40",
                  attrs: {
                    src: item.thumb,
                    fit: "contain",
                    "preview-src-list": scope.row.orderGoods.map(i => i.thumb)
                  }
                }), _c("div", {
                  directives: [{
                    name: "copy",
                    rawName: "v-copy",
                    value: item.third_goods_id || item.title,
                    expression: "item.third_goods_id || item.title"
                  }],
                  staticClass: "ml10 cursor"
                }, [_vm._v(_vm._s(item.third_goods_id || item.title))])], 1);
              });
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            prop: "changeUserPackage.package_no",
            label: "新包裹号",
            width: "100"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [scope.row.changeUserPackage ? _c("div", {
                directives: [{
                  name: "copy",
                  rawName: "v-copy",
                  value: scope.row.changeUserPackage.package_no,
                  expression: "scope.row.changeUserPackage.package_no"
                }],
                staticClass: "cursor"
              }, [_vm._v(_vm._s(scope.row.changeUserPackage.package_no))]) : _vm._e()];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            prop: "package_no",
            label: "包裹号",
            width: "100"
          }
        }), _c("el-table-column", {
          attrs: {
            prop: "shelf_no",
            label: "货架号",
            width: "90"
          }
        }), _c("el-table-column", {
          attrs: {
            prop: "weight",
            label: "重量(kg)",
            width: "80"
          }
        })], 1)];
      }
    }])
  }), _c("w-dialog", {
    directives: [{
      name: "dialogDrag",
      rawName: "v-dialogDrag"
    }],
    attrs: {
      title: "转仓",
      width: "60%"
    },
    on: {
      cancel: function ($event) {
        _vm.visible = false;
        _vm.model = {};
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_vm._t("footer", function () {
          return [_c("el-button", {
            attrs: {
              size: _vm.$store.getters["size"]
            },
            on: {
              click: function ($event) {
                _vm.visible = false;
              }
            }
          }, [_vm._v("关闭")])];
        })];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("p", {
    staticClass: "p5 f16 blue"
  }, [_vm._v("转仓成功，新包裹如下，请打印包裹标签")]), _c("el-table", {
    key: "result",
    attrs: {
      data: _vm.list,
      size: _vm.$store.getters["size"],
      stripe: "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "orderGoods",
      label: "商品",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return _vm._l(scope.row.orderGoods, function (item) {
          return _c("div", {
            staticClass: "w100 flex"
          }, [_c("el-image", {
            staticClass: "w-image-40",
            attrs: {
              src: item.thumb,
              fit: "contain",
              "preview-src-list": scope.row.orderGoods.map(i => i.thumb)
            }
          }), _c("div", {
            directives: [{
              name: "copy",
              rawName: "v-copy",
              value: item.third_goods_id || item.title,
              expression: "item.third_goods_id || item.title"
            }],
            staticClass: "ml10 cursor"
          }, [_vm._v(_vm._s(item.third_goods_id || item.title))])], 1);
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "package_no",
      label: "包裹号",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "weight",
      label: "重量(kg)",
      width: "80"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: 100 * _vm.$store.getters["sizeRate"],
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.toPrint(scope.row);
            }
          }
        }, [_vm._v("打印标签")])];
      }
    }])
  })], 1)], 1), _c("OrderPackageChangeUserAddDialog", {
    ref: "orderPackageChangeUserAddDialog",
    on: {
      done: _vm.getList
    }
  }), _c("PrintRecipt", {
    ref: "printRecipt"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
import UserAddDialog from './dialog/userAddDialog';
import UserCouponDialog from './dialog/userCouponDialog';
import BindOldSystem from '@/views/user/dialog/bindOldSystem.vue';
export default {
  name: 'UserIndex',
  components: {
    UserAddDialog,
    UserCouponDialog,
    BindOldSystem
  },
  data() {
    return {
      module: 'user',
      operates: {
        del: false,
        look: false,
        add: false,
        edit: true,
        multiDel: false,
        other: [{
          title: '绑定',
          type: 'warning',
          click: row => this.bindOldSystem(row)
        }]
        // recycle: { autoLink: true },
      }
    };
  },

  methods: {
    getList() {
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$refs.userAddDialog.open(row);
    },
    onAdd() {
      this.$refs.userAddDialog.open({});
    },
    onCoupon(row) {
      this.$refs.userCouponDialog.open(row);
    },
    bindOldSystem(row) {
      this.$refs.bindOldSystem.open(row);
    }
  }
};
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import OrderRefundAddDialog from '@/views/orderRefund/dialog/orderRefundAddDialog';
import WorkAddDialog from '@/views/work/dialog/workAddDialog';
import RefundTable from '@/views/orderRefund/refund';
import PayTable from '@/views/orderPay/pay';
import PrintRecipt from '@/components/Print/PrintRecipt';
import dayjs from 'dayjs';
import WorkTable from '@/views/work/index.vue';
import CouponTable from '@/views/userCoupon/coupon.vue';
import UserMsgAddDialog from '@/views/userMsg/dialog/userMsgAddDialog';
export default {
  title: 'OrderCombineGet',
  components: {
    UserMsgAddDialog,
    CouponTable,
    WorkTable,
    PayTable,
    WorkAddDialog,
    OrderRefundAddDialog,
    RefundTable,
    PrintRecipt
  },
  data() {
    return {
      module: 'orderCombine',
      activeNames: ['orderCombine', 'orderCombinePackages'],
      oldActiveNames: ['orderCombine', 'orderCombinePackages'],
      orderCombine: {
        id: undefined
      },
      orderCombinePackages: [],
      orderPackages: [],
      histories: [],
      photos: [],
      orderPays: [],
      orderRefunds: [],
      userAddressList: [],
      orderCombineAdditions: [],
      orderCombineReports: [],
      formOrderCombine: {},
      loading: false,
      form: {},
      formSecond: {},
      additionTree: [],
      formAction: '',
      formActionSecond: '',
      title: '修改',
      labelWidth: '120px'
    };
  },
  computed: {
    order() {
      return order;
    },
    size_() {
      return this.$store.getters['size'];
    },
    canEdit() {
      return [5, 6, 99].indexOf(this.orderCombine.status) === -1;
    }
  },
  created() {
    this.orderCombine.id = this.$route.query.id;
    this.getList();
  },
  methods: {
    dayjs,
    getList() {
      this.$w_fun.post(`${this.module}/get`, {
        id: this.orderCombine.id
      }).then(res => {
        this.orderCombine = res.data.detail;
        this.userAddressList = this.orderCombine.orderCombineAddresses || [];
        this.setOrderForm(this.orderCombine);
        this.loadDataList('orderCombinePackages');
        if (this.$refs.wDialogForm.visible) {
          this.getForm().options.order_combine_address_id = this.userAddressList.map(i => {
            return {
              label: i.consignee + '(' + i.phone + ')',
              value: i.id
            };
          });
        }
      });
    },
    setOrderForm({
      id,
      user_id,
      paid_at,
      created_at,
      delivery_at,
      amount,
      trans_amount,
      addition_amount,
      refund,
      discount,
      origin_amount,
      fee,
      userCoupons,
      exchange_rate,
      cost,
      store_amount,
      paid_amount,
      status_text,
      user_status,
      pay_status,
      order_no,
      comment,
      user_remark,
      remark,
      other_amount,
      other_amount_title,
      user = {},
      transFee = {}
    }) {
      this.formOrderCombine = {
        order_no: {
          label: '订单号',
          value: order_no,
          copy: true
        },
        user_id: {
          label: '会员专属码',
          value: user_id,
          copy: true
        },
        created_at: {
          label: '下单时间',
          value: created_at
        },
        status: {
          label: '订单状态',
          value: status_text
        },
        phone: {
          label: '手机',
          value: user.phone,
          copy: true
        },
        paid_at: {
          label: '支付时间',
          value: paid_at
        },
        trans_fee: {
          label: '运输渠道',
          value: transFee && transFee.title
        },
        nickname: {
          label: '昵称',
          value: user.nickname
        },
        delivery_at: {
          label: '发出时间',
          value: delivery_at
        },
        comment: {
          label: '用户留言',
          value: comment,
          width: '100%'
        },
        remark: {
          label: '备注',
          value: remark,
          width: '100%'
        },
        user_remark: {
          label: '用户可见备注',
          value: user_remark,
          width: '100%'
        }
      };
    },
    handleChange(v) {
      if (this.oldActiveNames.length < v.length) {
        const f = v.filter(i => !this.oldActiveNames.includes(i));
        for (const item of f) {
          if (item !== 'orderCombine') {
            this.loadDataList(item);
          } else {
            this.getList();
          }
        }
      }
      this.oldActiveNames = v;
    },
    loadDataList(v) {
      if (this.activeNames.indexOf(v) === -1) {
        this.oldActiveNames.push(v);
        this.activeNames.push(v);
      }
      if (v === 'orderRefunds') {
        this.$nextTick(() => {
          this.$refs.refundTable && this.$refs.refundTable.getList();
        });
        return;
      }
      if (v === 'orderPays') {
        this.$nextTick(() => {
          this.$refs.payTable && this.$refs.payTable.getList();
        });
        return;
      }
      if (v === 'works') {
        this.$nextTick(() => {
          this.$refs.workTable && this.$refs.workTable.getList();
        });
        return;
      }
      if (v === 'coupons') {
        this.$nextTick(() => {
          this.$refs.couponTable && this.$refs.couponTable.getList();
        });
        return;
      }
      this.$w_fun.post(`${this.module}/${v}`, {
        id: this.orderCombine.id
      }).then(res => {
        this[v] = res.data.list;
      });
    },
    editOrderCombine() {
      this.form = {
        id: {
          show: false,
          value: this.orderCombine.id
        },
        // trans_fee_category_id: {
        //   label: '一级运输渠道',
        //   value: this.orderCombine.transFee ? this.orderCombine.transFee.trans_fee_category_id : undefined,
        //   formType: 'select',
        //   options: 'transFeeCategory/select',
        //   methods: {
        //     change: async v => {
        //       this.getForm().model.trans_fee_id = undefined
        //       const { data } = await this.$w_fun.post('transFee/select?trans_fee_category_id_match=' + v)
        //       this.getForm().options.trans_fee_id = data.list
        //     }
        //   }
        // },
        // trans_fee_id: {
        //   label: '二级运输渠道',
        //   value: this.orderCombine.trans_fee_id,
        //   formType: 'select',
        //   options: 'transFee/select?trans_fee_category_id_match=' + (this.orderCombine.transFee ? this.orderCombine.transFee.trans_fee_category_id : 0)
        // },
        remark: {
          label: '备注',
          value: this.orderCombine.remark,
          required: false,
          max: 500,
          formType: 'textarea'
        },
        user_remark: {
          label: '用户可见备注',
          value: this.orderCombine.user_remark,
          required: false,
          max: 500,
          formType: 'textarea'
        }
      };
      this.formAction = 'orderCombine/edit';
      this.$refs.wDialogForm.visible = true;
    },
    async toPay() {
      const {
        data
      } = await this.$w_fun.post('orderCombine/storeAmount', {
        id: this.orderCombine.id
      });
      this.form = {
        id: {
          show: false,
          value: this.orderCombine.id
        },
        // exchange_rate: { label: '汇率', value: this.orderCombine.exchange_rate, formType: 'number', attrs: { step: 0.0001 } },
        fee: {
          label: '手续费',
          value: this.orderCombine.fee,
          formType: 'amount',
          methods: {
            change: v => {
              this.calcAmount();
            }
          }
        },
        store_amount: {
          label: '超期存储费',
          value: data ? data.amount : 0,
          formType: 'amount',
          attrs: {
            exchange_rate: this.orderCombine.exchange_rate
          },
          required: false,
          methods: {
            change: v => {
              this.calcAmount();
            }
          }
        },
        other_amount: {
          label: '其他费用',
          value: this.orderCombine.other_amount,
          formType: 'amount',
          attrs: {
            exchange_rate: this.orderCombine.exchange_rate
          },
          required: false,
          methods: {
            change: v => {
              this.calcAmount();
            }
          }
        },
        other_amount_title: {
          label: '其他费用名称',
          value: '',
          show: m => m.other_amount > 0
        }
      };
      let i = 1;
      for (const item of this.orderCombinePackages) {
        this.form['order_combine_package_' + i] = {
          label: '捆包' + i,
          value: `增值服务：${item.addition_amount}円，运费：${item.trans_amount}円，体积重：${item.v_w}kg，重量：${item.weight}kg`,
          readonly: true
        };
        i++;
      }
      if (this.orderCombine.amount > 0) {
        this.form.old_amount = {
          label: '上次核算金额',
          value: this.orderCombine.amount,
          readonly: true
        };
      }
      if (this.orderCombine.paid_amount > 0) {
        this.form.paid_amount = {
          label: '已支付金额',
          value: this.orderCombine.paid_amount,
          readonly: true
        };
      }
      this.form.total = {
        label: '合计',
        value: 0,
        readonly: true
      };
      if (this.orderCombine.paid_amount > 0) {
        this.form.refund_tips = {
          show: m => m.total < this.orderCombine.paid_amount,
          label: '退款提示',
          value: '核算金额低于已支付金额，请先退差价',
          readonly: true,
          style: 'red'
        };
      }
      this.form.tips = {
        label: '',
        value: '核算并发送给用户支付',
        readonly: true
      };
      this.formAction = 'orderCombine/toPay';
      this.$refs.wDialogForm.visible = true;
      this.$nextTick(() => {
        this.calcAmount();
      });
    },
    async toCancel() {
      let amount = 0;
      if (this.orderCombine.status > 0) {
        const {
          data
        } = await this.$w_fun.post('orderCombine/getCancelAmount');
        amount = data && data.amount ? data.amount : 0;
      }
      this.form = {
        id: {
          show: false,
          value: this.orderCombine.id
        },
        cancel_amount: {
          show: this.orderCombine.status > 0,
          label: '取消费用',
          value: amount,
          formType: 'amount'
        },
        remark: {
          label: '备注',
          value: this.orderCombine.remark,
          required: false,
          max: 500,
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          }
        },
        user_remark: {
          label: '用户可见备注',
          value: this.orderCombine.user_remark,
          required: false,
          max: 500,
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          }
        },
        package_ids: {
          show: this.orderCombine.status > 0,
          label: '重新入库包裹',
          value: [],
          required: false,
          formType: 'packageIds'
        }
      };
      this.formAction = 'orderCombine/cancel';
      this.$refs.wDialogForm.visible = true;
      await this.$nextTick();
      await this.$nextTick();
      this.$refs.wPackagesSelectTable.onRefresh();
    },
    rollCancel() {
      this.$confirm('确认撤回取消吗？', '撤回提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`${this.module}/rollCancel`, {
          id: this.orderCombine.id
        }).then(res => {
          this.getList();
        });
      }).catch(() => {});
    },
    addWork() {
      this.$refs.workAddDialog.open({
        obj_type_id: 1,
        obj_id: this.orderCombine.id,
        obj_title: '国际发货：' + this.orderCombine.order_no
      });
    },
    calcAmount() {
      this.$w_fun.post(`orderCombine/amount`, {
        id: this.orderCombine.id,
        fee: this.getForm().model.fee,
        store_amount: this.getForm().model.store_amount,
        other_amount: this.getForm().model.other_amount
      }).then(res => {
        this.getForm().model.total = res.data.amount + '円';
      });
    },
    toHandle() {
      this.$confirm('确认受理吗？', '受理提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`${this.module}/handle`, {
          id: this.orderCombine.id
        }).then(res => {
          this.getList();
        });
      }).catch(() => {});
    },
    editAddress({
      id,
      consignee,
      phone,
      id_card_no,
      province_id,
      city_id,
      region_id,
      detail,
      id_card_front,
      id_card_back
    }) {
      this.formSecond = {
        order_combine_id: {
          value: this.orderCombine.id,
          show: false
        },
        consignee: {
          label: '收件人',
          value: consignee
        },
        phone: {
          label: '电话',
          value: phone
        },
        id_card_no: {
          label: '身份证',
          value: id_card_no
        },
        location: {
          label: '地区',
          value: region_id ? [province_id, city_id, region_id] : [],
          formType: 'cascader',
          options: {
            url: '/address/select',
            nodeKey: 'pid_match'
          },
          lazy: true
        },
        detail: {
          label: '详细地址',
          value: detail
        },
        id_card_front: {
          label: '身份证正面',
          value: [{
            path: id_card_front,
            url: id_card_front
          }],
          formType: 'upload',
          attrs: {
            drag: true,
            accept: '.jpg,.png,.jpeg'
          },
          type: 'array',
          required: false
        },
        id_card_back: {
          label: '身份证正面',
          value: [{
            path: id_card_back,
            url: id_card_back
          }],
          formType: 'upload',
          attrs: {
            drag: true,
            accept: '.jpg,.png,.jpeg'
          },
          type: 'array',
          required: false
        }
      };
      if (id) {
        this.formSecond.id = {
          show: false,
          value: id
        };
        this.formActionSecond = 'orderCombineAddress/edit';
      } else {
        this.formActionSecond = 'orderCombineAddress/add';
      }
      this.$refs.wSecondDialogForm.visible = true;
    },
    deleteAddress(row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`orderCombineAddress/delete`, {
          id: row.id
        }).then(res => {
          this.getList();
        });
      }).catch(() => {});
    },
    editReport({
      id,
      goods_cat_id,
      quantity,
      amount,
      goods_cat_other
    }) {
      this.form = {
        order_combine_id: {
          show: false,
          value: this.orderCombine.id
        },
        goods_cat_id: {
          label: '商品分类',
          value: goods_cat_id,
          formType: 'select',
          options: 'adminSetting/select?module_match=orderCombineReportCategory',
          required: false
        },
        goods_cat_other: {
          show: m => m.goods_cat_id === 0,
          label: '商品分类其他',
          value: goods_cat_other
        },
        quantity: {
          label: '数量',
          value: quantity
        },
        amount: {
          label: '金额',
          value: amount
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = 'orderCombineReport/edit';
      } else {
        this.formAction = 'orderCombineReport/add';
      }
      this.$refs.wDialogForm.visible = true;
    },
    delReport(row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`orderCombineReport/delete`, {
          id: row.id
        }).then(res => {
          this.loadDataList('orderCombineReports');
        });
      }).catch(() => {});
    },
    async editAddition(row) {
      await this.getAdditionTree();
      let tree = JSON.parse(JSON.stringify(this.additionTree));
      if (!row.id) {
        tree.map(i => {
          i.children = i.children.map(j => {
            j.disabled = this.orderCombineAdditions.map(k => k.addition_id).indexOf(j.value) > -1;
            return j;
          });
          return i;
        });
      }
      console.log(tree);
      this.form = {
        order_combine_id: {
          show: false,
          value: this.orderCombine.id
        },
        addition_id: {
          label: '增值服务',
          value: row.addition_id,
          formType: 'treeRadio',
          options: tree,
          attrs: {
            disabled: row.id,
            expanded: true
          }
        },
        comment: {
          value: row.comment || '',
          label: '备注',
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          },
          max: 500,
          required: false
        }
      };
      if (row.id) {
        this.form.id = {
          show: false,
          value: row.id
        };
        this.formAction = `orderCombineAddition/edit`;
      } else {
        this.formAction = `orderCombineAddition/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    deleteAddition(row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`orderCombineAddition/delete`, {
          id: row.id
        }).then(res => {
          this.loadDataList('orderCombineAdditions');
          this.loadDataList('orderCombinePackages');
        });
      }).catch(() => {});
    },
    setOrderCombinePackageForm({
      id,
      tracking_no,
      inner_tracking_no,
      order_combine_address_id,
      trans_amount,
      addition_amount,
      weight,
      v_w,
      trans_fee_id,
      transFee = {},
      l,
      w,
      h,
      orderCombinePackageAdditions = []
    }) {
      weight = weight || this.orderPackages.reduce((a, b) => a + b.weight, 0);
      this.form = {
        order_combine_id: {
          show: false,
          value: this.orderCombine.id
        },
        // order_package_ids: {
        //   label: '包裹',
        //   value: id ? this.orderPackages.filter(i => i.order_combine_package_id === id).map(i => i.package_no): [],
        //   formType: 'select',
        //   options: this.orderPackages.filter(i => i.order_combine_package_id === 0).map(i => {return { label: i.package_no, value: i.id }}),
        //   attrs: {
        //     multiple: true
        //   }
        // },
        trans_fee_category_id: {
          label: '一级运输渠道',
          value: id ? transFee && transFee.trans_fee_category_id : this.orderCombine.transFee.trans_fee_category_id,
          formType: 'select',
          options: 'transFeeCategory/select',
          methods: {
            change: async v => {
              this.getForm().model.trans_fee_id = undefined;
              const {
                data
              } = await this.$w_fun.post('transFee/select?trans_fee_category_id_match=' + v);
              this.getForm().options.trans_fee_id = data.list;
            }
          },
          required: false,
          width: '45%',
          readonly: this.orderCombine.status > 2
        },
        trans_fee_id: {
          label: '二级运输渠道',
          value: id ? trans_fee_id : this.orderCombine.trans_fee_id,
          formType: 'select',
          options: 'transFee/select?trans_fee_category_id_match=' + (id ? transFee && transFee.trans_fee_category_id : this.orderCombine.transFee.trans_fee_category_id),
          required: false,
          width: '45%',
          methods: {
            change: v => {
              this.calcVW();
            }
          },
          readonly: this.orderCombine.status > 2
        },
        order_combine_address_id: {
          label: '收货地址',
          value: order_combine_address_id,
          formType: 'select',
          options: this.userAddressList.map(i => {
            return {
              label: i.consignee + '(' + i.phone + ')',
              value: i.id
            };
          }),
          attrs: {
            quickAdd: () => {
              this.editAddress({});
            }
          },
          width: '33%'
        },
        tracking_no: {
          label: '物流单号',
          value: tracking_no,
          required: false,
          width: '33%'
        },
        inner_tracking_no: {
          label: '国内物流单号',
          value: inner_tracking_no,
          required: false,
          width: '33%'
        },
        l: this.sizeFormItem('长', l),
        w: this.sizeFormItem('宽', w),
        h: this.sizeFormItem('高', h),
        weight: {
          label: '重量',
          value: weight,
          formType: 'number',
          attrs: {
            append: 'kg'
          },
          required: false,
          width: '33%',
          methods: {
            change: v => {
              this.calcTransAmount();
            }
          },
          readonly: this.orderCombine.status > 2
        },
        v_w: {
          label: '体积重',
          value: v_w,
          formType: 'number',
          required: false,
          width: '33%',
          methods: {
            change: v => {
              this.calcTransAmount();
            }
          },
          readonly: this.orderCombine.status > 2
        },
        trans_amount: {
          label: '运费',
          value: trans_amount,
          formType: 'amount',
          attrs: {
            exchange_rate: this.orderCombine.exchange_rate
          },
          min: 0,
          required: false,
          width: '33%',
          readonly: this.orderCombine.status > 2
        },
        addition_amount: {
          label: '增值服务费',
          value: addition_amount || 0,
          formType: 'additionAmount',
          required: false,
          readonly: this.orderCombine.status > 2
        },
        orderCombinePackageAdditions: {
          label: '',
          value: orderCombinePackageAdditions || [],
          formType: 'addition',
          required: false
        },
        remark: {
          label: '备注',
          value: this.orderCombine.remark,
          required: false,
          max: 500
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = 'orderCombinePackage/edit';
      } else {
        this.formAction = 'orderCombinePackage/add';
      }
      this.$refs.wDialogForm.visible = true;
      if (orderCombinePackageAdditions && orderCombinePackageAdditions.length > 0) {
        this.$nextTick(() => {
          let isCalc = false;
          for (const item of orderCombinePackageAdditions) {
            if (item.amount === 0) {
              isCalc = true;
              this.changePackageAddition(item);
            }
          }
          isCalc || this.changePackageAddition({});
        });
      }
    },
    addOrderCombinePackage() {
      this.setOrderCombinePackageForm({
        order_combine_address_id: this.userAddressList.length > 1 ? undefined : this.userAddressList.length === 1 ? this.userAddressList[0].id : undefined
      });
    },
    editOrderCombinePackage(row) {
      this.setOrderCombinePackageForm(row);
    },
    sizeFormItem(label, value) {
      return {
        label: label,
        value: value,
        required: false,
        formType: 'number',
        attrs: {
          append: 'cm'
        },
        width: '33%',
        methods: {
          change: v => {
            this.calcVW();
          }
        },
        readonly: this.orderCombine.status > 2
      };
    },
    async getAdditionTree() {
      if (this.additionTree.length === 0) {
        const {
          data
        } = await this.$w_fun.post('addition/combine');
        this.additionTree = data.list.map(i => {
          return {
            label: i.title,
            value: i.id + '-',
            children: i.children.map(j => {
              return {
                label: j.title,
                value: j.id
              };
            })
          };
        });
      }
    },
    async addOrderCombinePackageAddition() {
      await this.getAdditionTree();
      let tree = JSON.parse(JSON.stringify(this.additionTree));
      this.formSecond = {
        addition_id: {
          label: '增值服务',
          value: undefined,
          formType: 'treeRadio',
          options: tree.map(i => {
            i.children = i.children.map(j => {
              j.disabled = this.getForm().model.orderCombinePackageAdditions.map(k => k.addition_id).indexOf(j.value) > -1;
              return j;
            });
            return i;
          }),
          attrs: {
            expanded: true
          }
        }
      };
      this.formActionSecond = '';
      this.$refs.wSecondDialogForm.visible = true;
    },
    deletePackageAddition(index) {
      this.getForm().model.orderCombinePackageAdditions.splice(index, 1);
      this.changePackageAddition({});
    },
    async changePackageAddition(row) {
      let amount = 0;
      if (row.addition_id) {
        amount = await this.getAdditionAmount(row);
      }
      let total = 0;
      for (const item of this.getForm().model.orderCombinePackageAdditions) {
        if (item.addition_id === row.addition_id) {
          item.amount = amount;
        }
        total += item.amount;
      }
      this.getForm().model.addition_amount = total;
    },
    async getAdditionAmount(row) {
      const {
        data
      } = await this.$w_fun.post(`addition/amount`, {
        additions: [row]
      });
      return data ? data.amount : 0;
    },
    calcVW() {
      const model = this.getForm().model;
      const options = this.getForm().options.trans_fee_id;
      model.v_w = 0;
      if (options && options.length > 0) {
        for (const o of options) {
          if (o.value === model.trans_fee_id) {
            if (o.v_w_coefficient > 0) {
              let precision = 1;
              if (o.v_w_formula_type === 2) {
                precision = 2;
              }
              model.v_w = parseFloat((Math.ceil(model.l * model.w * model.h * Math.pow(10, precision) / o.v_w_coefficient) / Math.pow(10, precision)).toFixed(precision));
            }
          }
        }
      }
      this.calcTransAmount();
    },
    calcTransAmount() {
      const model = this.getForm().model;
      if (model.trans_fee_id && (model.weight > 0 || model.v_w > 0)) {
        this.$w_fun.post('transFee/amount', {
          id: model.trans_fee_id,
          weight: model.weight,
          v_w: model.v_w
        }).then(res => {
          model.trans_amount = res.data.amount;
        });
      }
    },
    delOrderCombinePackage(row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`orderCombinePackage/delete`, {
          id: row.id
        }).then(res => {
          this.loadDataList('orderCombinePackages');
          this.loadDataList('orderPackages');
        });
      }).catch(() => {});
    },
    reStore(row) {
      this.form = {
        id: {
          show: false,
          value: row.id
        },
        package_no: {
          label: '包裹号',
          value: undefined,
          attrs: {
            disabled: true
          },
          required: false
        },
        size_type: {
          label: '包裹大小',
          value: 2,
          formType: 'radio',
          options: '/orderPackage/sizeType',
          attrs: {
            isButton: true
          }
        },
        weight: {
          label: '重量',
          value: row.weight,
          formType: 'number',
          attrs: {
            append: 'kg'
          }
        },
        print: {
          label: '',
          value: '',
          formType: 'print',
          show: m => m.package_no,
          required: false
        }
      };
      this.formAction = 'store/addOrderCombinePackage';
      this.$refs.wDialogForm.visible = true;
    },
    getPackageSummaries({
      columns,
      data
    }) {
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (index === 1) {
          sums[index] = '数量:' + data.length;
          return;
        }
        if (column.property === 'weight') {
          sums[index] = parseFloat(data.map(item => Number(item[column.property])).reduce((sum, value) => sum + value, 0).toFixed(3));
        }
        if (column.property === 'orderGoods') {
          sums[index] = 0;
          for (const item of data) {
            if (item.orderGoods) {
              for (const g of item.orderGoods) {
                sums[index] += g.amount;
              }
            }
          }
          sums[index] = '金额：' + sums[index];
        }
      });
      return sums;
    },
    reStorePackage(row) {
      this.$confirm('确认重新入库吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`store/reAdd`, {
          id: row.id,
          order_combine_id: this.orderCombine.id
        }).then(res => {
          this.loadDataList('orderPackages');
        });
      }).catch(() => {});
    },
    addPay() {
      this.$refs.payTable.addPay({
        order_combine_id: {
          show: false,
          value: this.orderCombine.id
        }
      }, this.orderCombine.exchange_rate);
    },
    async beforeSubmit(model) {
      if (this.formAction === 'orderCombine/cancel') {
        const confirm = await this.$confirm('确定要取消订单吗？', '提示', {
          type: 'warning'
        }).catch(() => {});
        if (!confirm) {
          return false;
        }
      }
      return model;
    },
    formDone(v) {
      var _v$package;
      switch (this.formAction) {
        case 'orderCombine/edit':
        case 'orderCombine/toPay':
        case 'orderCombineAddress/edit':
        case 'orderCombineAddress/add':
        case 'orderCombineAddress/delete':
        case 'orderCombine/cancel':
          this.getList();
          break;
        case 'orderCombinePackage/add':
          this.loadDataList('orderPackages');
          this.loadDataList('orderCombinePackages');
          break;
        case 'orderCombineReport/edit':
        case 'orderCombineReport/add':
          this.loadDataList('orderCombineReports');
          break;
        case 'orderCombineAddition/add':
        case 'orderCombineAddition/edit':
          this.loadDataList('orderCombineAdditions');
          this.loadDataList('orderCombinePackages');
          break;
        case 'orderCombinePackage/edit':
          this.loadDataList('orderCombinePackages');
          break;
        case 'orderRefund/verify':
        case 'orderRefund/add':
          this.loadDataList('orderRefunds');
          break;
        case 'store/addOrderCombinePackage':
          this.getForm().model.package_no = v === null || v === void 0 ? void 0 : (_v$package = v.package) === null || _v$package === void 0 ? void 0 : _v$package.package_no;
          this.$refs.wDialogForm.visible = true;
          this.printPackageNo();
          // todo 打印包裹码
          break;
      }
    },
    secondDone(v) {
      switch (this.formActionSecond) {
        case 'orderCombineAddress/add':
        case 'orderCombineAddress/edit':
          this.getList();
          break;
        default:
          for (const cat of this.additionTree) {
            for (const item of cat.children) {
              if (item.value === v.addition_id) {
                this.getForm().model.orderCombinePackageAdditions.push({
                  addition: {
                    title: item.label
                  },
                  addition_id: item.value,
                  comment: '',
                  amount: 0,
                  goods_quantity: 0
                });
                this.changePackageAddition({
                  addition_id: item.value
                });
              }
            }
          }
      }
    },
    getForm() {
      return this.$refs.wDialogForm ? this.$refs.wDialogForm.$refs.form : null;
    },
    printPackageNo() {
      // todo 打印包裹码
      this.$refs.printRecipt.printReceipt(this.orderCombine.user_id, this.getForm().model);
    }
  }
};
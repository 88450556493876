export default {
  name: 'ConfigIndex',
  data() {
    return {
      form: {},
      formAction: 'config/save',
      activeName: '2',
      loading: false,
      configs: [{
        group: 2,
        title: '站点',
        list: [{
          name: 'exchange_rate',
          title: '人民币汇率'
        }, {
          name: 'large_bill_amount',
          title: '大额订单拍照'
        }, {
          name: 'change_amount_range',
          title: '订单变更额度'
        }, {
          name: 'store_day_limit',
          title: '超期存储天数'
        }, {
          name: 'notice_text',
          title: '提示文字'
        }, {
          name: 'site_info',
          title: '站点信息'
        }]
      }, {
        group: 3,
        title: '客服',
        list: [{
          name: 'pre_sale',
          title: '售前'
        }, {
          name: 'after_sale',
          title: '售后'
        }]
      }, {
        group: 4,
        title: '打印',
        list: [{
          name: 'print_device_barcode',
          title: '条码'
        }]
      }, {
        group: 5,
        title: '支付',
        list: [{
          name: 'pay_alipay',
          title: '支付宝'
        }, {
          name: 'pay_wechat',
          title: '微信'
        }]
      }]
    };
  },
  mounted() {
    this.$refs['menu' + this.configs[0].list[0].name][0].$el.click();
  },
  methods: {
    handleOpen(a, b) {},
    async changeMenu(menu) {
      const name = menu.name;
      const {
        data
      } = await this.$w_fun.post('config/get', {
        name
      });
      let value = data.detail ? data.detail.value : undefined;
      switch (name) {
        case 'exchange_rate':
          this.form = {
            value: {
              label: '人民币汇率',
              value: value || 0,
              formType: 'number',
              attrs: {
                step: 0.0001
              },
              min: 0.0001
            }
          };
          break;
        case 'change_amount_range':
          this.form = {
            value: {
              label: '订单变更额度',
              value: value || 0,
              formType: 'number',
              attrs: {
                step: 1,
                append: '円'
              },
              min: 0,
              tooltip: '0或不填，不起用'
            }
          };
          break;
        case 'notice_text':
          value = value || {};
          this.form = {
            pay_notice: {
              label: '支付前提示文字',
              value: value.pay_notice || '',
              formType: 'textarea',
              attrs: {
                autosize: {
                  minRows: 2,
                  maxRows: 4
                }
              }
            },
            bargain_notice: {
              label: '留言提示文字',
              value: value.bargain_notice || '',
              formType: 'textarea',
              attrs: {
                autosize: {
                  minRows: 2,
                  maxRows: 4
                }
              },
              required: false
            }
          };
          break;
        case 'large_bill_amount':
          this.form = {
            value: {
              label: '大额订单拍照',
              value: value || 0,
              formType: 'amount'
            }
          };
          break;
        case 'store_day_limit':
          value = value || {};
          this.form = {
            size_type_1: {
              label: '',
              value: '小包裹',
              readonly: true
            },
            store_day_limit_1: {
              label: '超期存储天数',
              value: value.store_day_limit_1,
              formType: 'number',
              attrs: {
                step: 1,
                append: '天'
              },
              min: 0,
              tooltip: '0或不填，不限制'
            },
            price_1: {
              label: '每超一天多少钱',
              value: value.price_1,
              formType: 'number',
              min: 0,
              show: m => m.store_day_limit_1 > 0
            },
            currency_1: {
              label: '货币类型',
              value: value.currency_1,
              formType: 'radio',
              options: 'siteConfig/currency',
              show: m => m.store_day_limit_1 > 0,
              attrs: {
                isButton: true
              }
            },
            empty: {
              label: '',
              value: '　',
              readonly: true
            },
            size_type_2: {
              label: '',
              value: '大包裹',
              readonly: true
            },
            store_day_limit_2: {
              label: '超期存储天数',
              value: value.store_day_limit_2,
              formType: 'number',
              attrs: {
                step: 1,
                append: '天'
              },
              min: 0,
              tooltip: '0或不填，不限制'
            },
            price_2: {
              label: '每超一天多少钱',
              value: value.price_2,
              formType: 'number',
              min: 0,
              show: m => m.store_day_limit_2 > 0
            },
            currency_2: {
              label: '货币类型',
              value: value.currency_2,
              formType: 'radio',
              options: 'siteConfig/currency',
              show: m => m.store_day_limit_2 > 0,
              attrs: {
                isButton: true
              }
            }
          };
          break;
        case 'site_info':
          value = value || {};
          this.form = {
            name: {
              label: '站点名称',
              value: value.name,
              required: false
            },
            icp: {
              label: '备案信息',
              value: value.icp,
              required: false
            },
            tel: {
              label: '联系电话',
              value: value.tel,
              required: false
            },
            title: {
              label: '标题',
              value: value.title,
              required: false
            },
            keyword: {
              label: '关键词',
              value: value.keyword,
              required: false
            },
            desc: {
              label: '简介',
              value: value.desc,
              formType: 'textarea',
              attrs: {
                autosize: {
                  minRows: 2,
                  maxRows: 4
                }
              },
              required: false
            },
            tj_code: {
              label: '统计代码',
              value: value.tj_code,
              formType: 'textarea',
              attrs: {
                autosize: {
                  minRows: 4,
                  maxRows: 8
                }
              },
              required: false
            },
            cs_code: {
              label: '客服代码',
              value: value.cs_code,
              formType: 'textarea',
              attrs: {
                autosize: {
                  minRows: 4,
                  maxRows: 8
                }
              },
              required: false
            }
          };
          break;
        case 'pre_sale':
          value = value || {};
          this.form = {
            url: {
              label: '链接',
              value: value.url
            },
            key: {
              label: 'KEY',
              value: value.key,
              required: false
            },
            secret: {
              label: '秘钥',
              value: value.secret,
              required: false
            }
          };
          break;
        case 'after_sale':
          value = value || {};
          this.form = {
            url: {
              label: '链接',
              value: value.url
            },
            key: {
              label: 'KEY',
              value: value.key,
              required: false
            },
            secret: {
              label: '秘钥',
              value: value.secret,
              required: false
            }
          };
          break;
        case 'print_device_barcode':
          value = value || {};
          this.form = {
            device: {
              label: '设备名称',
              value: value.device
            },
            width: {
              label: '宽',
              value: value.width,
              required: false,
              attrs: {
                append: 'mm'
              }
            },
            height: {
              label: '高',
              value: value.height,
              required: false,
              attrs: {
                append: 'mm'
              }
            }
          };
          break;
      }
      this.form.name = {
        value: name,
        show: false
      };
      this.form.title = {
        value: menu.title,
        show: false
      };
    },
    handleClick() {
      this.$nextTick(_ => {
        for (const item of this.configs) {
          if (item.group.toString() === this.activeName) {
            this.$refs['menu' + item.list[0].name][0].$el.click();
          }
        }
      });
    },
    async onSubmit() {
      try {
        this.loading = true;
        const result = await this.$refs['wForm' + this.activeName][0].submit();
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    beforeSubmit(model) {
      const m = {
        value: undefined,
        name: model.name,
        title: model.title,
        group: this.activeName
      };
      if (typeof model.value !== 'undefined') {
        m.value = model.value;
        m.type = 0;
      } else {
        m.value = {};
        m.type = 1;
        for (const k in model) {
          if (k === 'name' || k === 'title' || k === 'group') {
            continue;
          }
          m.value[k] = model[k];
        }
      }
      return m;
    }
  }
};
import OrderRefundAddDialog from './dialog/orderRefundAddDialog';
export default {
  name: 'OrderRefundIndex',
  components: {
    OrderRefundAddDialog
  },
  data() {
    return {
      module: 'orderRefund',
      operates: {
        del: false,
        look: false,
        add: false,
        edit: false,
        multiDel: false
        // recycle: { autoLink: true },
      }
    };
  },

  methods: {
    getList() {
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$refs.orderRefundAddDialog.open(row);
    },
    onAdd() {
      this.$refs.orderRefundAddDialog.open({});
    }
  }
};
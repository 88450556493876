import "core-js/modules/es.array.push.js";
export default {
  name: 'OrderTogetherDialog',
  data() {
    return {
      module: 'order',
      formAction: 'orderPackage/add',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm(selection) {
      const togetherGoodsIds = [];
      for (const i in selection) {
        if (i === '0') {
          continue;
        }
        togetherGoodsIds.push(...selection[i].orderGoods.map(i => i.id));
      }
      this.form = {
        order_id: {
          show: false,
          value: selection[0].id
        },
        order_goods_id: {
          show: false,
          value: selection[0].orderGoods.map(i => i.id)
        },
        together_goods_ids: {
          show: false,
          value: togetherGoodsIds
        }
      };
      for (const i in selection) {
        this.form['order' + i] = {
          label: '订单' + (parseInt(i) + 1),
          value: `${selection[i].order_no}，商品：${selection[i].orderGoods.map(i => i.third_goods_id).join('；')}，用户：${selection[i].user_id}` + (selection[i].user ? `[${selection[i].user.phone}]` : ''),
          readonly: true
        };
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data) {
      this.setForm(data);
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};